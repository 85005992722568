import { Col, Container, Row } from 'react-bootstrap';
import { SectionHeader } from '../SectionHeader';

export const Blogers = () => {
  return (
    <section className='bg-white py-5'>
      <Container className='py-5'>
        <Row>
          <Col md={6} xl={5}>
            <picture>
              <img src='/images/blogers.png' className='img-fluid'></img>
            </picture>
          </Col>
          <Col xs={12} md={6} xl={{ span: 4, offset: 1 }} className='pt-md-5'>
            <SectionHeader title='service for blogers'>
              Get more using our platform by placing targeted ads.
            </SectionHeader>
            <p className='text-secondary'>
              AdMary is the win-win solution for entrepreneurs who are looking
              to boost their business with targeted advertising across social
              networks, blogs, podcasts, and other resources and mass media
              owners or bloggers who are looking for additional sources to sell
              ads.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
