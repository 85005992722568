import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Card from '@/components/Card';
import { PrimaryBadge } from '@/components/ui/badges';
import { RoundedAvatar } from '@/components/ui/avatars';
import { ProgressBar } from '@/components/ui/bars/progress';
import styles from './TaskCard.module.scss';

export const TaskCard = (props) => {
  const { id, all, completed, date, status, ...dragProps } = props;

  const progressWidth = useMemo(
    () => (completed * 100) / all,
    [all, completed]
  );

  return (
    <Card className={styles.card} {...dragProps}>
      <div className={styles.title}>
        <Link className={styles.link} to={`/tasks/${id}`}>
          Task №{id}
        </Link>
      </div>
      <PrimaryBadge className={styles.badge}>{date}</PrimaryBadge>
      <div className={styles.counter}>
        {completed}/{all}
      </div>
      <RoundedAvatar size='small' />
      <ProgressBar className={styles.progress} progressWidth={progressWidth} />
    </Card>
  );
};
