import Card from '@/components/Card';
import { AvatarWithStatus } from '@/components/ui/avatars/AvatarWithStatus';
import { BorderedBadge, PrimaryBadge } from '@/components/ui/badges';
import { ProgressBar } from '@/components/ui/bars/progress';
import { Link } from 'react-router-dom';
import styles from './OffersCard.module.scss';

export const OfferCard = (props) => {
  const { offer, link, price, body, date, progress, deadline, variant } = props;

  return (
    <Card className={styles.card}>
      <AvatarWithStatus className={styles.avatar} />
      <h4 className={styles.title}>Offer №{offer}</h4>
      <a className={styles.link} href='#'>
        Advertising link
      </a>
      <BorderedBadge className={styles.price}>${price}</BorderedBadge>
      <p className={styles.descr}>{body}</p>
      <PrimaryBadge className={styles.date}>{date}</PrimaryBadge>
      <ProgressBar className={styles.progress} variant={variant} />
      <PrimaryBadge className={styles.progressCount}>{progress}</PrimaryBadge>
      <PrimaryBadge className={styles.deadline} warningText>
        deadline: {deadline}
      </PrimaryBadge>
      <Link className={styles.cardLink} to={`/offers/${offer}`} />
    </Card>
  );
};
