import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Dashboard.module.scss';
import Sidebar from './Sidebar';
import Header from './Header';

export const Dashboard = ({ wide, title, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, {
            [styles.visible]: visible,
          })}
          onClose={() => setVisible(false)}
        />
        <Header title={title} onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {/* {title && <div className={cn('h3', styles.title)}>{title}</div>} */}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
