import { Container, Row, Col, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cn from 'classnames';

const links = [
  {
    title: 'Support',
    link: '/support',
  },
  {
    title: 'Terms & Conditins',
    link: '/privacy',
  },
  {
    title: 'Privacy Policy',
    link: '/privacy',
  },
];

export const Footer = () => {
  return (
    <footer className='fw-normal'>
      <div className='position-relative'>
        <div className='position-absolute top-0 start-0 bg-white h-50 w-100'></div>
        <div className='position-absolute bottom-0 start-0 bg-dark h-50 w-100'></div>
        <Container className='z-1 position-relative'>
          <div className='bg-warning py-5 px-4 px-xl-0 text-center rounded'>
            <div className='fw-semibold fs-1'>
              Start your proper
              <br />
              advertising today
            </div>
            <div className='pt-3 pb-4'>
              Deliver your ads straight to the most valuable potential customers
            </div>
            <Row className='justify-content-center'>
              <Col xs={6} md={4} lg={3} xl={2}>
                <Button variant='light' className='w-100'>
                  Watch free tutorials
                </Button>
              </Col>
              <Col xs={6} md={4} lg={3} xl={2}>
                <Button variant='dark' className='w-100'>
                  Get Started Now
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className='py-5 bg-dark text-light'>
        <Container>
          <Row className='flex-column-reverse flex-lg-row text-center text-lg-start'>
            <Col>© Copyright 2022, All Rights Reserved</Col>
            <Col xs='auto' className='mb-3 mb-lg-0'>
              {links?.map(({ title, link }, idx) => (
                <Link
                  key={link}
                  className={cn('text-light', idx !== 0 ? 'ms-5' : '')}
                  to={link}
                >
                  {title}
                </Link>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};
