import { useMemo } from 'react';
import cn from 'classnames';
import styles from './Stats.module.scss';

export const Stats = (props) => {
  const { icon, value, prop, size, className } = props;

  const getSizeVariant = useMemo(
    () => (size) => {
      switch (size) {
        case 'small':
          return styles.small;
        case 'medium':
          return styles.medium;
        case 'large':
          return styles.large;
        default:
          return styles.medium;
      }
    },
    [size]
  );

  return (
    <div className={cn(styles.stats, getSizeVariant(size), className)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.value}>{value}</div>
      <div className={styles.prop}>{prop}</div>
    </div>
  );
};
