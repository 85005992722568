import React from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.scss';

const Checkbox = (props) => {
  const {
    className,
    classCheckboxTick,
    content,
    value,
    onChange,
    checked,
    reverse,
  } = props;

  return (
    <label
      className={cn(styles.checkbox, className, { [styles.reverse]: reverse })}
    >
      <input
        className={styles.input}
        type='checkbox'
        onChange={onChange}
        checked={checked}
        value={value}
      />
      <span className={styles.inner}>
        <span className={cn(styles.tick, classCheckboxTick)}></span>
        {content && (
          <>
            <span className={styles.text}>{content}</span>
          </>
        )}
      </span>
    </label>
  );
};

export default Checkbox;
