import { AvatarWithStatus } from '@/components/ui/avatars';
import cn from 'classnames';
import styles from './UserInfographic.module.scss';

export const UserInfographic = (props) => {
  const { title, tags } = props;

  return (
    <div className={styles.infographic}>
      <AvatarWithStatus className={styles.avatar} />
      <div className={cn(styles.title, 'fs-3 fw-bold')}>{title}</div>
      <ul className={styles.tags}>
        {tags?.map(({ id, title }) => (
          <li key={id} className={styles.tagsItem}>
            {title}
          </li>
        ))}
      </ul>
    </div>
  );
};
