import { Breadcrumb as BsBreadcrumb } from 'react-bootstrap';
import cn from 'classnames';
import styles from './Breadcrumb.module.scss';

const Breadcrumb = (props) => {
  const { className, children } = props;
  return (
    <BsBreadcrumb className={cn(styles.list, className)}>
      {children}
    </BsBreadcrumb>
  );
};

const BreadcrumbItem = (props) => {
  const { active, href, linkAs, children } = props;

  return (
    <BsBreadcrumb.Item
      className={styles.item}
      active={active}
      href={href}
      linkAs={linkAs}
    >
      {children}
    </BsBreadcrumb.Item>
  );
};

Breadcrumb.Item = BreadcrumbItem;

export { Breadcrumb };
