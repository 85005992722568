export const Youtube = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 700 490'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M685.6 76.65C681.595 61.8753 673.792 48.4063 662.968 37.5819C652.144 26.7576 638.675 18.9551 623.9 14.95C569.1 0.500001 350 0.5 350 0.5C350 0.5 130.9 0.500001 76.1 14.95C46.1 23 22.5 46.65 14.4 76.65C0 131.4 0 245 0 245C0 245 0 359.15 14.4 413.35C22.5 443.35 46.15 467 76.1 475.05C130.9 489.5 350 489.5 350 489.5C350 489.5 569.1 489.5 623.9 475.05C638.675 471.045 652.144 463.242 662.968 452.418C673.792 441.594 681.595 428.125 685.6 413.35C700 358.6 700 245 700 245C700 245 700 131.4 685.6 76.65Z'
        fill='#FF0000'
      />
      <path d='M279.65 350.5L461.85 245.6L279.65 140.1V350.5Z' fill='white' />
    </svg>
  );
};
