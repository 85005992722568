import { Form, Field } from 'react-final-form';
import { Form as BsForm, Button } from 'react-bootstrap';
import { required } from '@/services/validation';
import cn from 'classnames';
import styles from './Actions.module.scss';
import formStyles from '@/components/forms/Forms.module.scss';

export const Actions = (props) => {
  const { setMessage } = props;

  const onSubmit = ({ message }, form) => {
    setMessage(message, form);
  };

  return (
    <div className={styles.actions}>
      <Form
        initialValues={{}}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            className={cn(formStyles.form, 'd-flex align-items-start')}
            onSubmit={(values) => handleSubmit(values, form)}
          >
            <Field name='message' validate={required}>
              {({ input, meta }) => (
                <BsForm.Group className='flex-grow-1'>
                  <BsForm.Control
                    className={styles.control}
                    onChange={input.onChange}
                    value={input.value}
                    type='text'
                    placeholder='Type to add your message'
                  />
                  {meta.error && meta.touched && (
                    <BsForm.Control.Feedback
                      className={formStyles.feedback}
                      type='invalid'
                    >
                      {meta.error}
                    </BsForm.Control.Feedback>
                  )}
                </BsForm.Group>
              )}
            </Field>
            <Button type='submit' disabled={submitting}>
              Send
            </Button>
          </form>
        )}
      />
    </div>
  );
};
