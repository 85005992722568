export const Instagram = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 299 299'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.79'>
        <path
          d='M235.859 85.6875C243.962 85.6875 250.531 79.1187 250.531 71.0156C250.531 62.9126 243.962 56.3438 235.859 56.3438C227.756 56.3438 221.188 62.9126 221.188 71.0156C221.188 79.1187 227.756 85.6875 235.859 85.6875Z'
          fill='url(#paint0_linear_1_12)'
        />
        <path
          d='M238.188 0H62.875C28.9219 0 0 28.0312 0 62V237.312C0 271.266 28.9219 298.453 62.875 298.453H238.188C272.156 298.453 298.438 271.281 298.438 237.312V62C298.438 28.0312 272.156 0 238.188 0ZM270.312 228.781C270.392 234.461 269.333 240.099 267.196 245.363C265.06 250.626 261.89 255.408 257.874 259.426C253.858 263.443 249.078 266.615 243.815 268.753C238.552 270.891 232.914 271.953 227.234 271.875H73.8437C49.5781 271.875 29.6875 253.031 29.6875 228.781V75.375C29.7328 63.6809 34.4005 52.4791 42.6725 44.2129C50.9445 35.9468 62.1496 31.287 73.8437 31.25H227.219C251.563 31.25 270.312 51.125 270.312 75.375V228.766V228.781Z'
          fill='url(#paint1_linear_1_12)'
        />
        <path
          d='M149.219 63.2188C132.083 63.2188 115.332 68.3001 101.084 77.8203C86.8359 87.3405 75.7309 100.872 69.1733 116.703C62.6156 132.535 60.8999 149.955 64.2429 166.762C67.586 183.569 75.8377 199.007 87.9546 211.124C100.072 223.24 115.509 231.492 132.316 234.835C149.123 238.178 166.543 236.463 182.375 229.905C198.206 223.347 211.738 212.242 221.258 197.994C230.778 183.746 235.859 166.995 235.859 149.859C235.859 126.881 226.731 104.843 210.483 88.5952C194.235 72.3469 172.197 63.2188 149.219 63.2188ZM149.219 206.25C138.066 206.25 127.163 202.943 117.89 196.746C108.616 190.55 101.389 181.743 97.1206 171.439C92.8526 161.135 91.7358 149.797 93.9117 138.858C96.0875 127.919 101.458 117.872 109.345 109.985C117.231 102.099 127.279 96.7281 138.218 94.5523C149.156 92.3764 160.494 93.4931 170.799 97.7612C181.103 102.029 189.91 109.257 196.106 118.53C202.302 127.804 205.609 138.706 205.609 149.859C205.605 164.814 199.663 179.155 189.088 189.729C178.514 200.303 164.173 206.246 149.219 206.25Z'
          fill='url(#paint2_linear_1_12)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1_12'
          x1='225.484'
          y1='81.3906'
          x2='246.234'
          y2='60.6406'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBB03B' />
          <stop offset='0.19' stopColor='#EA6D48' />
          <stop offset='0.44' stopColor='#D4145A' />
          <stop offset='0.64' stopColor='#BB0B5B' />
          <stop offset='0.86' stopColor='#A6035D' />
          <stop offset='1' stopColor='#9E005D' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1_12'
          x1='18.7031'
          y1='280.609'
          x2='281.062'
          y2='18.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBB03B' />
          <stop offset='0.19' stopColor='#EA6D48' />
          <stop offset='0.44' stopColor='#D4145A' />
          <stop offset='0.64' stopColor='#BB0B5B' />
          <stop offset='0.86' stopColor='#A6035D' />
          <stop offset='1' stopColor='#9E005D' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1_12'
          x1='87.9532'
          y1='211.125'
          x2='210.484'
          y2='88.5938'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FBB03B' />
          <stop offset='0.19' stopColor='#EA6D48' />
          <stop offset='0.44' stopColor='#D4145A' />
          <stop offset='0.64' stopColor='#BB0B5B' />
          <stop offset='0.86' stopColor='#A6035D' />
          <stop offset='1' stopColor='#9E005D' />
        </linearGradient>
      </defs>
    </svg>
  );
};
