import cn from 'classnames';
import { DefaultBadge } from '../DefaultBadge';
import styles from './PrimaryBadge.module.scss';

export const PrimaryBadge = (props) => {
  const { className, warningText, children } = props;

  const textStyle = warningText ? styles.warning : '';

  return (
    <DefaultBadge className={cn(styles.badge, textStyle, className)}>
      {children}
    </DefaultBadge>
  );
};
