import cn from 'classnames';
import styles from './Help.module.scss';
import { Link } from 'react-router-dom';
import Icon from '@/components/Icon';
import Item from './Item';

const items = [
  {
    title: 'Promote your product',
    image: '/images/video-thumb.jpg',
    image2x: '/images/video-thumb.jpg',
    avatar: '/images/admary_avatar_1.png',
    time: '3 mins',
  },
  {
    title: 'Use guidelines',
    image: '/images/video-thumb-2.jpg',
    image2x: '/images/video-thumb-2.jpg',
    avatar: '/images/admary_avatar_1.png',
    time: '8 mins',
  },
  {
    title: 'Life & work update',
    image: '/images/video-thumb-3.jpg',
    image2x: '/images/video-thumb-3.jpg',
    avatar: '/images/admary_avatar_1.png',
    time: '12 mins',
  },
];

const menu = [
  {
    title: 'Help center',
    icon: 'message',
    counter: 8,
    colorCounter: '#FDEFDC',
    url: '/help-center/',
  },
];

const Help = ({ className, visible, setVisible, onClose }) => {
  const handleClose = () => {
    onClose();
    setVisible(false);
  };

  return (
    <>
      <div
        className={cn(styles.help, className, {
          [styles.active]: visible,
        })}
      >
        <div className={styles.head}>
          <Icon name='help' size='24' />
          Help & getting started
          <button className={styles.close} onClick={() => setVisible(false)}>
            <Icon name='close' size='24' />
          </button>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
        <div className={styles.menu}>
          {menu.map((x, index) => (
            <Link
              className={styles.link}
              to={x.url}
              key={index}
              onClick={() => handleClose()}
            >
              <Icon name={x.icon} size='24' />
              {x.title}
              {x.arrow && (
                <div className={styles.arrow}>
                  <Icon name='arrow-next' size='24' />
                </div>
              )}
              {x.counter && <div className={styles.counter}>8</div>}
            </Link>
          ))}
        </div>
      </div>
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Help;
