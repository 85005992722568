import { Dashboard } from '@/components/Layouts';
import { useParams } from 'react-router-dom';
import { UserInfographic } from '@/components/ui/infographics';
import { Button } from 'react-bootstrap';
import Card from '@/components/Card';
import { Stats } from '@/components/Stats';
import { CheckList } from '@/components/CheckList';
import { PrimaryBadge } from '@/components/ui/badges';
import { Chat } from '@/components/Chat';
import cn from 'classnames';
import styles from './Task.module.scss';

const list = [
  {
    id: 1,
    title: 'Advertising post',
  },
  {
    id: 2,
    title: 'Like',
  },
];

export const Task = (props) => {
  const { slug } = props;

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <UserInfographic title={`Offer №${slug}`} tags={list} />
        <div className={styles.btns}>
          <Button variant='light'>Save</Button>
          <Button>Apply</Button>
        </div>
      </div>
      <div className={styles.body}>
        <Card>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <div className='fs-5 fw-bold'>About the job</div>
            <PrimaryBadge>12 Feb 2023 20:52</PrimaryBadge>
          </div>
          <div className={styles.about}>
            <p>
              Нужно написать пост, чтобы он выглядел как естественная
              рекомендация(не рекламная) на наш сервис впн и в нем разместить
              ссылку на наш аккаунт @easygo_vpn
            </p>
            <p>
              Причины рекомендаций могут быть различными: для доступа в
              инстаграм, в другие сервисы недоступные в России, торренты,
              нейросеть ChatGpt, тормозят бесплатные ВПН, большая стоимость
              текущего ВПН и т.п. Чем естественней и реальней причина для вас -
              тем лучше.
            </p>
            <div className='fw-bold mb-2'>
              Более подробная информация о нашем сервисе:
            </div>
            <p>
              Настраивается через телеграм в несколько кликов, работает без
              рекламы, не замедляет работу. 3 дня бесплатного доступа для
              тестирования сервиса стоимость 99 рублей в месяц, при оплате за
              полгода сразу 499 рублей, за год 799 рублей откроет доступ к
              социальным сетям.
            </p>
            <div className='fw-bold fs-6'>Links:</div>
            <a href='#'>https://www.instagram.com/easygo_vpn/</a>
          </div>
        </Card>
        <Chat />
      </div>
      <aside className={styles.side}>
        <Card className='mb-3'>
          <div className='fw-bold fs-5'>$1500</div>
          <div className={cn(styles.cardSubtitle, 'mb-4')}>Cost offer</div>
          <div className={styles.stats}>
            <Stats
              className='mb-3'
              size='small'
              value={'Deadline'}
              prop={'7 days 1:19'}
            />
            <Stats
              className='mb-3'
              size='small'
              value={'Social network'}
              prop={'Instagram'}
            />
            <Stats
              className='mb-3'
              size='small'
              value={'Person information'}
              prop={'Gabriela Pires'}
            />
            <Stats
              className='mb-3'
              size='small'
              value={'Contacts'}
              prop={'co*****ct@gmail.com'}
            />
            <Stats
              size='small'
              value={'Target location'}
              prop={'Los-Angeles'}
            />
          </div>
        </Card>
        <Card>
          <CheckList />
        </Card>
      </aside>
    </div>
  );
};

export const LayoutedTask = () => {
  const { slug } = useParams();

  return (
    <Dashboard title={`Tasks / №${slug}`}>
      <Task slug={slug} />
    </Dashboard>
  );
};
