import Card from '@/components/Card';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { ListGroup } from '@/components/ListGroup';
import styles from './HelpCenter.module.scss';
import { Breadcrumb } from '@/components/Breadcrumb';
import { Dashboard } from '../../components/Layouts';

const questions = [
  {
    theme: 'How to search bloggers',
    questions: [
      {
        title: 'How do I search bloggers?',
        slug: 'how-do-i-search-bloggers',
      },
      {
        title: 'How do I describe a task for a blogger?',
        slug: 'how-do-i-search-bloggers',
      },
      {
        title: 'How do I search bloggers, websites and radio?',
        slug: 'how-do-i-search-bloggers-websites-and-radio',
      },
    ],
  },
  {
    theme: 'How to receive the money ',
    questions: [
      {
        title: 'How quickly will I receive the money after confirmation?',
        slug: 'how-quickly-will-i-receive-the-money-after-confirmation',
      },
      {
        title: 'Can I pay to bloggers for goods?',
        slug: 'can-i-pay-to-bloggers-for-goods',
      },
    ],
  },
];

const topQuestions = [
  {
    id: 1,
    title: 'How can I get a refund for an unfinished deals?',
    text: 'How quickly will I receive the money after confirmation?',
    slug: 'how-can-i-get-a-refund-for-an-unfinished-deals',
  },
  {
    id: 2,
    title: 'How do I change or cancel my experience task?',
    text: 'How quickly will I receive the money after confirmation?',
    slug: 'how-do-i-change-or-cancel-my-experience-task',
  },
  {
    id: 3,
    title: 'How quickly will I receive the money after confirmation?',
    text: 'How quickly will I receive the money after confirmation?',
    slug: 'how-quickly-will-i-receive-the-money-after-confirmation',
  },
];

export const HelpCenter = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <Breadcrumb className='mb-5'>
        <Breadcrumb.Item linkAs='span'>
          <Link to={'/help-center/'}>Help Center</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xs={12} md={7} lg={8} className='mb-4 mb-md-0'>
          {questions?.map(({ theme, questions }, idx) => (
            <div
              key={theme}
              className={cn(idx < questions.length - 1 ? 'mb-4' : '')}
            >
              <h3 className={cn(styles.title, 'mb-2')}>{theme}</h3>
              <Card className={styles.card}>
                <ListGroup>
                  {questions?.map(({ title, slug }) => (
                    <ListGroup.Item key={title}>
                      <Link
                        className='text-reset'
                        to={`${pathname}${slug}`}
                        state={{ title }}
                      >
                        {title}
                      </Link>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card>
            </div>
          ))}
        </Col>
        <Col as='aside'>
          <h3 className={cn(styles.title, 'mb-4')}>Top questions</h3>
          {topQuestions?.map(({ id, title, text, slug }, idx) => (
            <Card
              key={id}
              className={cn(
                styles.asideCard,
                idx < topQuestions.length - 1 ? 'mb-4' : 'mb-0'
              )}
            >
              <h4 className={styles.asideCardTitle}>{title}</h4>
              <p className={styles.asideCardText}>{text}</p>
              <Link
                className={cn(styles.asideCardLink, 'text-reset')}
                to={`${pathname}${slug}`}
                state={{ title }}
              >
                Read more
              </Link>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export const LayoutedHelpCenter = () => {
  return (
    <Dashboard title='Help Center'>
      <HelpCenter />
    </Dashboard>
  );
};
