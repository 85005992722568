import React, { useState, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import { Link, Navigate, useLocation } from 'react-router-dom';
import TextInput from '@/components/TextInput';
import Image from '@/components/Image';
import { Alert } from 'react-bootstrap';
import { useGetGoogleLoginQuery, useLoginMutation } from '../../redux/api';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './SignIn.module.scss';

export const SignIn = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [login, { isError, error }] = useLoginMutation();
  const { data: googleData } = useGetGoogleLoginQuery();

  const { userInfo, isLoading } = useSelector((state) => state.user);

  const heightWindow = use100vh();

  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await login({ username, password }).unwrap();
    } catch (error) {}
  };

  useEffect(() => {
    document.title = 'Login to Admary';
  }, []);

  if (isLoading) return null;

  if (userInfo) {
    return <Navigate to={'/search'} replace />;
  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col} style={{ minHeight: heightWindow }}>
          <div className={styles.head}>
            <Link className={styles.logo} to='/'>
              <Image
                className={styles.pic}
                src='/images/logo-dark.svg'
                srcDark='/images/logo-dark.svg'
                alt='Admary'
              />
            </Link>
            <div className={styles.info}>
              Don’t have an account?{' '}
              <Link className={styles.link} to='/signup'>
                Sign up
              </Link>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={cn('h2', styles.title)}>Sign in</div>
            <div className={styles.head}>
              <div className={styles.subtitle}>Sign up with Open account</div>
              <div className={styles.btns}>
                <a
                  className={cn('button-stroke', 'reset-link', styles.button)}
                  href={googleData?.url}
                >
                  <img src='/images/content/google.svg' alt='Google' />
                  Google
                </a>
                <button className={cn('button-stroke', styles.button)}>
                  <Image
                    className={styles.pic}
                    src='/images/content/apple-dark.svg'
                    srcDark='/images/content/apple-light.svg'
                    alt='Apple'
                  />
                  Apple ID
                </button>
              </div>
            </div>
            <form className={styles.form} onSubmit={submitHandler}>
              <div className={styles.body}>
                {isError &&
                  Object.values(error.data).map((value) => (
                    <Alert variant='danger'>{value}</Alert>
                  ))}
                <div className={styles.subtitle}>
                  Or continue with email address
                </div>
                <TextInput
                  className={styles.field}
                  name='email'
                  type='text'
                  placeholder='Your email'
                  required
                  icon='mail'
                  value={username}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextInput
                  className={styles.field}
                  name='password'
                  type='password'
                  placeholder='Password'
                  required
                  icon='lock'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button className={cn('button', styles.button)}>Sign in</button>
                <div className={styles.note}>
                  This site is protected by reCAPTCHA and the Google Privacy
                  Policy.
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={styles.col}>
          <img src='/images/login-img.png' alt='Login' />
        </div>
      </div>
    </>
  );
};
