import cn from 'classnames';
import styles from './RoundedAvatar.module.scss';

import avatarPlaceholder from '@/assets/placeholders/user-avatar.svg';

export const RoundedAvatar = (props) => {
  const { src, size, className } = props;

  return (
    <div
      className={cn(styles.avatar, styles[size] || styles.medium, className)}
    >
      <img src={src || avatarPlaceholder} alt='user avatar' />
    </div>
  );
};
