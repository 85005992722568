import React from 'react';
import cn from 'classnames';
import styles from './Loader.module.scss';

const Loader = ({ className, white }) => {
  return (
    <div
      className={cn(styles.loader, className, {
        [styles.loaderWhite]: white,
      })}
    ></div>
  );
};

export default Loader;
