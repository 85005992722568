import { useMemo } from 'react';
import cn from 'classnames';
import styles from './ProgressBar.module.scss';

export const ProgressBar = (props) => {
  const { className, progressWidth } = props;

  const memoVariant = useMemo(() => {
    if (progressWidth < 40) return styles.danger;
    if (progressWidth > 40 && progressWidth < 80) return styles.warning;
    return styles.success;
  }, [progressWidth]);

  return (
    <div className={cn(styles.progress, memoVariant, className)}>
      <div className={styles.bar} style={{ width: `${progressWidth}%` }} />
    </div>
  );
};
