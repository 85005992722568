import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './ButtonsGroupFilter.module.scss';

export const ButtonsGroupFilter = (props) => {
  const { buttons, initialValue, onChange } = props;

  const lineRef = useRef(null);
  const buttonRef = useRef(null);

  const [activeValue, setActiveValue] = useState(initialValue);
  const [line, setLine] = useState({ width: 0, left: 0 });

  const onClickHandler = (value) => {
    setActiveValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    if (buttonRef) {
      const width = buttonRef?.current?.offsetWidth;
      const left = buttonRef?.current?.offsetLeft;

      setLine({ width, left });
    }
  }, [activeValue]);

  return (
    <div className={styles.buttons}>
      <span
        className={styles.line}
        ref={lineRef}
        style={{ width: line.width, left: line.left }}
      />
      {buttons?.map(({ name, value }) => (
        <button
          key={value}
          id={`button-filter-${value}`}
          type='button'
          className={cn(styles.button, {
            [styles.active]: activeValue === value,
          })}
          onClick={() => onClickHandler(value)}
          ref={activeValue === value ? buttonRef : null}
        >
          {name}
        </button>
      ))}
    </div>
  );
};
