import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useVerifyMutation } from '@/redux/api';
import { useSelector } from 'react-redux';

export const Confirm = () => {
  const [searchParams] = useSearchParams();

  const [verify, { isError, error }] = useVerifyMutation();

  const { userInfo, isLoading } = useSelector((state) => state.user);

  useEffect(() => {
    const signupVerify = async () => {
      try {
        const key = searchParams.get('key');
        await verify({ key }).unwrap();
      } catch (err) {}
    };

    signupVerify();
  }, []);

  // if (isLoading) return null;

  if (userInfo) {
    return <Navigate to={'/search'} replace />;
  }

  if (isError) return 'Verify error';

  return null;
};
