import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = (props) => {
  const { children } = props;
  const { userInfo, isLoading } = useSelector((state) => state.user);
  const location = useLocation();

  if (isLoading) return null;

  if (!userInfo) {
    return <Navigate to='/signin' state={{ from: location }} replace />;
  }

  return children;
};
