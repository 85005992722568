import cn from 'classnames';
import styles from './Card.module.scss';

export const Card = (props) => {
  const { icon, title, children } = props;

  return (
    <article className={cn(styles.card, 'py-4 px-3')}>
      <div className={styles.icon}>{icon}</div>
      <h4 className={styles.header}>{title}</h4>
      <div className={styles.body}>{children}</div>
    </article>
  );
};
