import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  userInfo: null,
  isLoading: true,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser(state, action) {
      state.userInfo = action.payload;
      state.isLoading = false;
    },
    removeUser(state, action) {
      state.userInfo = null;
      state.isLoading = false;
    },
  },
});

export const { addUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
