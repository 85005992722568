import React from 'react';
import cn from 'classnames';
import styles from './Card.module.scss';

const Card = (props) => {
  const {
    className,
    title,
    classTitle,
    classCardHead,
    head,
    children,
    ...dragProps
  } = props;

  return (
    <div
      className={cn(styles.card, className, {
        'drag-item': dragProps.draggable,
      })}
      {...dragProps}
    >
      {title && (
        <div className={cn(styles.head, classCardHead)}>
          <div className={cn(classTitle, styles.title)}>{title}</div>
          {head && head}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
