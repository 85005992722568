import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Card from '@/components/Card';
import Checkbox from '@/components/Checkbox';
import Dropdown from '@/components/Dropdown';
import { Range, getTrackBackground } from 'react-range';
import useDarkMode from 'use-dark-mode';
import cn from 'classnames';
import styles from './Filters.module.scss';

const options = ['Featured', 'Popular', 'New'];
const optionsRating = ['1 and up', '2 and up', '3 and up', '4 and up', '5'];
const filterLocation = ['London', 'New York', 'Berlin'];

const STEP = 1;
const MIN = 0;
const MAX = 120;

const initialsFilters = [
  { name: 'Instagram', checked: false },
  { name: 'Facebook', checked: false },
  { name: 'Youtube', checked: false },
  { name: 'Twitter', checked: false },
];

const Filters = ({ className, selectedFilters, setSelectedFilters }) => {
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState(options[0]);
  const [rating, setRating] = useState(optionsRating[3]);
  // const [selectedFilters, setSelectedFilters] = useState([]);
  const [values, setValues] = useState([32, 80]);
  const darkMode = useDarkMode(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const networkValues = searchParams.getAll('network');

  const handleSubmit = (e) => {
    alert();
  };

  const [filters, setFilters] = useState(
    initialsFilters.map(({ name }) => ({
      name,
      checked: networkValues.includes(name),
    }))
  );

  const onChangeNetworkHandler = (evt) => {
    const { value } = evt.target;
    const newFilters = filters.map(({ name, checked }) =>
      name === value ? { name, checked: !checked } : { name, checked }
    );

    if (evt.target.checked) {
      if (!networkValues.includes(value)) {
        searchParams.append('network', value);
      }
    } else {
      const updatedNetworkValues = networkValues.filter(
        (queryValue) => queryValue !== value
      );

      searchParams.delete('network');

      updatedNetworkValues.forEach((value) => {
        searchParams.append('network', value);
      });
    }

    setFilters(newFilters);
    setSearchParams(searchParams);
  };

  const [users, setUsers] = useState(false);

  return (
    <>
      <Card className='mb-3' title='Social network' classTitle='title-purple'>
        <div className={cn(styles.filters, className)}>
          <div className={styles.group}>
            {filters.map(({ name, checked }, index) => (
              <Checkbox
                className={styles.checkbox}
                content={name}
                value={name}
                checked={checked}
                onChange={onChangeNetworkHandler}
                key={index}
                reverse
              />
            ))}
          </div>
        </div>
      </Card>
      <Card className='mb-3' title='Location' classTitle='title-purple'>
        <div className={styles.group}>
          <div className={styles.item}>
            <div className={styles.list}>
              {filterLocation.map((x, index) => (
                <Checkbox
                  className={styles.checkbox}
                  content={x}
                  key={index}
                  reverse
                />
              ))}
            </div>
          </div>
        </div>
      </Card>
      <Card className='mb-3' title='Price estimates' classTitle='title-purple'>
        <div className={styles.group}>
          <div className={styles.item}>
            <Range
              values={values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(values) => {
                setValues(values);
              }}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    width: '100%',
                    paddingTop: 20,
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '4px',
                      width: '100%',
                      borderRadius: '2px',
                      background: getTrackBackground({
                        values,
                        colors: darkMode.value
                          ? ['#272B30', '#2A85FF', '#272B30']
                          : ['#EFEFEF', '#2A85FF', '#EFEFEF'],
                        min: MIN,
                        max: MAX,
                      }),
                      alignSelf: 'center',
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ index, props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '16px',
                    width: '16px',
                    borderRadius: '50%',
                    backgroundColor: '#FFF',
                    border: '2px solid #2A85FF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'inset 0px 2px 2px #FFFFFF',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 'calc(100% + 5px)',
                      color: '#fff',
                      fontWeight: '600',
                      fontSize: '12px',
                      lineHeight: '18px',
                      fontFamily: 'Inter',
                      padding: '4px 8px',
                      borderRadius: '8px',
                      backgroundColor: '#272B30',
                    }}
                  >
                    ${values[index].toFixed(0)}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Card>
      <Card title='Experience level' classTitle='title-purple'>
        <div className={styles.group}>
          <div className={styles.item}>
            <div className={styles.box}>
              <Dropdown
                className={styles.dropdown}
                classDropdownLabel={styles.label}
                classDropdownHead={styles.dropdownHead}
                value={rating}
                setValue={setRating}
                options={optionsRating}
                label='Rating'
                upBody
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Filters;
