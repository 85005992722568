import styles from './AvatarWithStatus.module.scss';
import userPlaceholder from '@/assets/placeholders/user-avatar.svg';
import { Instagram } from '@/components/ui/icons';
import cn from 'classnames';

export const AvatarWithStatus = (props) => {
  const { avatarUrl, className } = props;

  return (
    <div className={cn(styles.avatar, className)}>
      <div className={styles.avatarImg}>
        <img src={avatarUrl || userPlaceholder} />
      </div>
      <div className={styles.status}>
        <Instagram />
      </div>
    </div>
  );
};
