import { Container, Row, Col } from 'react-bootstrap';
import { Card } from './Card';
import { SectionHeader } from '../SectionHeader';

const services = [
  {
    id: 1,
    title: 'We help grow your business!',
    text: `AdMary is the win-win solution for entrepreneurs who are looking
    to boost their business with targeted advertising across social
    networks`,
    icon: (
      <svg width='50' height='50'>
        <use xlinkHref='#service-1'></use>
      </svg>
    ),
  },
  {
    id: 2,
    title: 'Get more from your advertising',
    text: `Get more using our platform by placing targeted ads on your blogs, channels, websites,  and other resources.`,
    icon: (
      <svg width='50' height='50'>
        <use xlinkHref='#service-2'></use>
      </svg>
    ),
  },
  {
    id: 3,
    title: 'The right ad to the right person, at the right time!',
    text: `AdMary allows you to deliver your ads straight to the most valuable potential customers across multiple resources.`,
    icon: (
      <svg width='50' height='50'>
        <use xlinkHref='#service-3'></use>
      </svg>
    ),
  },
];

export const Business = () => {
  return (
    <section className='py-5'>
      <Container>
        <SectionHeader title='service for business' align='center'>
          Admary helps
          <br />
          grow your business
        </SectionHeader>
        <Row>
          <Col xs={12} md={6} xl={5}>
            {services?.map(({ id, title, text, icon }) => (
              <Card key={id} title={title} icon={icon}>
                <p className='text-secondary'>{text}</p>
              </Card>
            ))}
          </Col>
          <Col>
            <picture>
              <img src='/images/business-service.png' className='img-fluid' />
            </picture>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
