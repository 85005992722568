import { Col, Row } from 'react-bootstrap';
import { DefaultBadge } from '@/components/ui/badges';
import { TaskCard } from '../TaskCard';
import styles from './Kanban.module.scss';
import { useState } from 'react';

export const Kanban = (props) => {
  const { tasks } = props;

  const initialBoards = [
    {
      id: 1,
      status: 'Pending',
      variant: 'danger',
      items: tasks?.filter(({ status }) => status === 'pending'),
    },
    {
      id: 2,
      status: 'In Progress',
      variant: 'warning',
      items: tasks?.filter(({ status }) => status === 'progress'),
    },
    {
      id: 3,
      status: 'Done',
      variant: 'success',
      items: tasks?.filter(({ status }) => status === 'done'),
    },
  ];

  const [boards, setBoards] = useState(initialBoards);

  const [currentBoard, setCurrentBoard] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const dragOverHandler = (evt, board, item) => {
    evt.preventDefault();
  };

  const dragLeaveHandler = (evt) => {};

  const dragStartHandler = (evt, board, item) => {
    setCurrentBoard(board);
    setCurrentItem(item);
  };

  const dragEndHandler = (evt) => {};

  const dropHandler = (evt, board, item) => {
    evt.preventDefault();
    evt.stopPropagation();
    const currentIndex = currentBoard.items.indexOf(currentItem);
    currentBoard.items.splice(currentIndex, 1);
    const dropIndex = board.items.indexOf(item);
    board.items.splice(dropIndex + 1, 0, currentItem);

    setBoards(
      boards.map((b) => {
        if (b.id === board.id) {
          return board;
        }

        if (b.id === currentBoard.id) {
          return currentBoard;
        }

        return b;
      })
    );
  };

  const dropCardHandler = (evt, board) => {
    board.items.push(currentItem);
    const currentIndex = currentBoard.items.indexOf(currentItem);
    currentBoard.items.splice(currentIndex, 1);

    setBoards(
      boards.map((b) => {
        if (b.id === board.id) {
          return board;
        }

        if (b.id === currentBoard.id) {
          return currentBoard;
        }

        return b;
      })
    );
  };

  return (
    <Row className={styles.board}>
      {boards?.map((board) => (
        <Col
          key={board.id}
          className='kanban-board'
          onDragOver={(evt) => dragOverHandler(evt)}
          onDrop={(evt) => dropCardHandler(evt, board)}
        >
          <div className='mb-3'>
            <DefaultBadge variant={board.variant}>{board.status}</DefaultBadge>
          </div>
          {board?.items?.map((item) => (
            <TaskCard
              key={item.id}
              {...item}
              draggable
              onDragOver={(evt) => dragOverHandler(evt)}
              onDragLeave={(evt) => dragLeaveHandler(evt)}
              onDragStart={(evt) => dragStartHandler(evt, board, item)}
              onDragEnd={(evt) => dragEndHandler(evt)}
              onDrop={(evt) => dropHandler(evt, board, item)}
            />
          ))}
        </Col>
      ))}
    </Row>
  );
};
