import { Header } from './Header';
import { Footer } from './Footer';
import styles from './Main.module.scss';

export const Main = (props) => {
  const { children } = props;

  return (
    <div className='bg-light'>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};
