import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SignUpForm } from '@/components/forms/SignUpForm';
import Code from './Code';
import Image from '../../components/Image';
import cn from 'classnames';
import styles from './SignUp.module.scss';

export const SignUp = () => {
  const heightWindow = '100%';

  useEffect(() => {
    document.title = 'Sign Up to Admary';
  }, []);

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link className={styles.logo} to='/'>
            <Image
              className={styles.pic}
              src='/images/logo-dark.svg'
              srcDark='/images/logo-dark.svg'
              alt='Admary'
            />
          </Link>
          <div className={styles.info}>
            Already a member?{' '}
            <Link className={styles.link} to='/signin'>
              Sign In
            </Link>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={cn('h2', styles.title)}>Sign Up</div>
          <SignUpForm />
        </div>
      </div>
      <div className={styles.col}>
        <img src='/images/login-img.png' alt='Login' />
        {/* <div className={styles.preview}>          
        </div> */}
      </div>
    </div>
  );
};
