import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import List from './List';
import Filters from './Filters';
import ConnectNetworks from './ConnectNetworks';
import { Dashboard } from '@/components/Layouts';
import styles from './Home.module.scss';

export const Search = () => {
  const { userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    document.title = 'Home page Admary';
  }, []);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.main}>
            <div className={styles.details}>
              <div className={styles.box}>
                <div className={styles.content}>{userInfo.username}</div>
                <div className={styles.content}>{userInfo.email}</div>
                <div className={styles.content}>{userInfo.name}</div>
                {/* <div className={styles.content}>{userDetail.has_youtube}</div> */}
                {/* <div>
                  {YTData ? (
                    <p>{YTData}</p>
                  ) : (
                    <button onClick={() => {}}>Request YT data</button>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <ConnectNetworks className='mb-4' />
          <List className={styles.card} />
        </div>
        <div className={styles.col}>
          <Filters className={styles.filters} />
        </div>
      </div>
    </>
  );
};

export const LayoutedSearch = () => {
  return (
    <Dashboard title='Search bloggers'>
      <Search />
    </Dashboard>
  );
};
