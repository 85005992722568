import React from 'react';
import Overview from './Overview';
import Transactions from './Transactions';
import { Dashboard } from '@/components/Layouts';
import TooltipGlodal from '@/components/TooltipGlodal';

import styles from './Statements.module.scss';

export const Statements = () => {
  return (
    <>
      <div className={styles.section}>
        {/* <Overview className={styles.card} /> */}
        <Transactions />
      </div>
      <TooltipGlodal />
    </>
  );
};

export const LayoutedStatements = () => {
  return (
    <Dashboard>
      <Statements />
    </Dashboard>
  );
};
