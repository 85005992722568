export const transactions = [
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: true,
    price: 98,
    earnings: 88.2,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: true,
    price: 85,
    earnings: 65.2,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: false,
    price: 98,
    earnings: -9.8,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: true,
    price: 98,
    earnings: 88.2,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: true,
    price: 85,
    earnings: 65.2,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: false,
    price: 98,
    earnings: -9.8,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: true,
    price: 98,
    earnings: 88.2,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: true,
    price: 85,
    earnings: 65.2,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: false,
    price: 98,
    earnings: -9.8,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: true,
    price: 98,
    earnings: 88.2,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: true,
    price: 85,
    earnings: 65.2,
  },
  {
    product: "Offer №48096",
    invoice: "Invoice: UI8-8934AS",
    date: "14 May 2023",
    status: false,
    price: 98,
    earnings: -9.8,
  },
];
