import React, { useState } from 'react';
import styles from './Sidebar.module.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import Icon from '@/components/Icon';
import Theme from '@/components/Theme';
import Dropdown from './Dropdown';
import Help from './Help';
import Image from '@/components/Image';

const navigation = [
  {
    title: 'Search',
    url: '/search',
    icon: 'search',
  },
  {
    title: 'My accounts',
    icon: 'profile-circle',
    slug: '/accounts',
    add: true,
    dropdown: [
      {
        title: 'Social networks',
        url: '/customers/overview',
      },
      {
        title: 'Web sites',
        url: '/customers/customer-list',
      },
    ],
  },
  {
    title: 'My offers',
    slug: 'customers',
    icon: 'mail',
    dropdown: [
      {
        title: 'Offers',
        url: '/offers',
      },
      {
        title: 'Tasks',
        url: '/tasks',
        counter: '2',
        colorCounter: '#CCDCFF',
      },
      {
        title: 'Customer list',
        url: '/customers/customer-list',
        counter: '28',
        colorCounter: '#CCDCFF',
      },
    ],
  },
  {
    title: 'Payments',
    slug: 'income',
    icon: 'payment',
    dropdown: [
      {
        title: 'Earning',
        url: '/income/statements',
      },
      {
        title: 'Payouts',
        url: '/income/payouts',
      },
    ],
  },
  {
    title: 'Promote',
    icon: 'promotion',
    url: '/promote',
  },
];

const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <svg width='24' height='24'>
            <use xlinkHref='#close'></use>
          </svg>
        </button>
        <Link className={styles.logo} to='/' onClick={onClose}>
          <Image
            className={styles.pic}
            src='/images/logo-dark.svg'
            srcDark='/images/logo-dark.svg'
            alt='Admary'
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.active]: pathname === x.url,
                })}
                to={x.url}
                key={index}
                onClick={onClose}
              >
                <Icon name={x.icon} size='24' />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <svg width='24' height='24'>
            <use xlinkHref='#arrow-right'></use>
          </svg>
          <svg width='24' height='24'>
            <use xlinkHref='#close'></use>
          </svg>
        </button>
        <div className={styles.foot}>
          <button className={styles.link} onClick={() => setVisibleHelp(true)}>
            <Icon name='help' size='24' />
            Help & getting started
            <div className={styles.counter}>8</div>
          </button>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
