export const Twitter = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 800 668'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M800 79.5882C770.605 92.9745 738.956 102.027 705.758 106.072C739.661 85.2701 765.674 52.2859 777.93 12.9938C746.188 32.2546 711.111 46.2667 673.64 53.7785C643.776 21.0831 601.092 0.666687 553.853 0.666687C463.274 0.666687 389.787 76.0247 389.787 168.958C389.787 182.152 391.196 194.961 394.013 207.288C257.604 200.258 136.691 133.326 55.6905 31.3877C41.5566 56.3305 33.4798 85.2701 33.4798 116.087C33.4798 174.448 62.4524 225.971 106.498 256.162C79.6385 255.344 54.2818 247.687 32.1182 235.168V237.238C32.1182 318.808 88.701 386.847 163.832 402.256C150.073 406.204 135.564 408.178 120.585 408.178C110.019 408.178 99.6889 407.167 89.6871 405.193C110.583 472.028 171.157 520.71 243 522.01C186.84 567.177 116.03 594.094 39.1617 594.094C25.9199 594.094 12.8192 593.323 1.52588e-05 591.782C72.6419 639.453 158.948 667.333 251.594 667.333C553.525 667.333 718.577 410.923 718.577 188.508C718.577 181.189 718.436 173.87 718.154 166.695C750.226 142.956 778.071 113.343 800 79.5882Z'
        fill='#00AAEC'
      />
    </svg>
  );
};
