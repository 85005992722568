import { useState } from 'react';
import dayjs from 'dayjs';
import { HistoryItem } from './HistoryItem';
import { Actions } from './Actions';
import styles from './Chat.module.scss';

const chatHistory = [
  {
    from: 'me',
    text: 'Добрый день',
    name: 'Gabriela Pires',
    date: dayjs() - 1000 * 60 * 30,
  },
  {
    from: 'someone',
    text: 'Отправляю дополнительные материалы',
    name: 'Henry Richardson',
    date: dayjs() - 1000 * 60 * 20,
  },
  {
    from: 'me',
    text: 'Отправлю вам ссылку на пост с рекламой',
    name: 'Gabriela Pires',
    date: dayjs() - 1000 * 60 * 15,
  },
  {
    from: 'someone',
    text: 'Жду результатов по размещению',
    name: 'Henry Richardson',
    date: dayjs() - 1000 * 60 * 5,
  },
];

export const Chat = (props) => {
  const {} = props;

  const [chatItems, setChatItems] = useState(chatHistory);

  const addMessage = (message, form) => {
    const time = dayjs();
    const newMessageItem = {
      from: 'me',
      text: message.trim(),
      name: 'Gabriela Pires',
      date: +time,
    };

    setChatItems([...chatItems, newMessageItem]);
    form.restart();
  };

  return (
    <div className={styles.chat}>
      <div className={styles.history}>
        {chatItems?.map((item) => (
          <HistoryItem key={item.date} {...item} />
        ))}
      </div>
      <Actions setMessage={addMessage} />
    </div>
  );
};
