import { Hero } from './Hero';
import { Business } from './Business';
import { Blogers } from './Blogers';
import { Advantages } from './Advatages';
import { Main } from '@/components/Layouts';

export const Home = () => {
  return (
    <>
      <Hero />
      <Business />
      <Blogers />
      <Advantages />
      <div className='bg-white py-5'>
        <div className='bg-white py-lg-5 py-md-3' />
      </div>
    </>
  );
};

export const LayoutedHome = () => {
  document.title = 'Easy way to grow your business - Admary.com';
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      'content',
      'AdMary is the win-win solution for entrepreneurs who are looking to boost their business with targeted advertising across social networks, blogs, podcasts, and other resources'
    );

  return (
    <Main>
      <Home />
    </Main>
  );
};
