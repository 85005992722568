import { Form, Field } from 'react-final-form';
import { Form as BsForm, Button, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { FileUpload } from '@/components/FileUpload';
import cn from 'classnames';
import styles from './CreateOfferForm.module.scss';
import formStyles from '@/components/forms/Forms.module.scss';

const advertisingTypeOptions = [
  { value: 'branding', label: 'Branding' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const advertisementFormatOptions = [
  { value: 'native integration', label: 'Native integration' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

export const CreateOfferForm = (props) => {
  const {} = props;

  const onSubmit = (values) => {
    console.log(values);
  };

  const selectChangeHandler = (selectedItem, input) => {
    const { value } = selectedItem;
    input.onChange(value);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form
          onSubmit={handleSubmit}
          className={cn(styles.form, formStyles.form)}
        >
          <Field name='advertisingType'>
            {({ input, meta }) => (
              <BsForm.Group>
                <BsForm.Label>Advertising type</BsForm.Label>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={advertisingTypeOptions}
                  onChange={(value) => selectChangeHandler(value, input)}
                />
              </BsForm.Group>
            )}
          </Field>
          <Field name='advertisementFormat'>
            {({ input, meta }) => (
              <BsForm.Group>
                <BsForm.Label>Advertisement format</BsForm.Label>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={advertisementFormatOptions}
                  onChange={(value) => selectChangeHandler(value, input)}
                />
              </BsForm.Group>
            )}
          </Field>
          <Field name='shortDescription'>
            {({ input, meta }) => (
              <BsForm.Group controlId='shortDescription'>
                <BsForm.Label>Short description</BsForm.Label>
                <BsForm.Control
                  onChange={input.onChange}
                  type='text'
                  placeholder='What a blogger should do'
                />
              </BsForm.Group>
            )}
          </Field>
          <Field name='advertisedLink'>
            {({ input, meta }) => (
              <BsForm.Group controlId='advertisedLink'>
                <BsForm.Label>Link to the advertised page:</BsForm.Label>
                <BsForm.Control
                  onChange={input.onChange}
                  type='text'
                  placeholder='What a blogger should do'
                />
              </BsForm.Group>
            )}
          </Field>
          <Field name='detail'>
            {({ input, meta }) => (
              <BsForm.Group controlId='detail' className={styles.detail}>
                <BsForm.Label>Detailed description:</BsForm.Label>
                <BsForm.Control
                  onChange={input.onChange}
                  as='textarea'
                  rows={5}
                  placeholder='Describe in as many details as possible'
                />
              </BsForm.Group>
            )}
          </Field>
          <Field name='attachments'>
            {({ input, meta }) => (
              <FileUpload
                title='Attachments'
                rowClassName={styles.file}
                titleClassName={styles.label}
                onChange={input.onChange}
              >
                Uploading documents, images, videos, and archives is allowed The
                maximum file size is 250 mb
              </FileUpload>
            )}
          </Field>
          <Row className='align-items-end row-gap-4'>
            <Col xs='auto' className='flex-grow-1'>
              <Field name='price'>
                {({ input, meta }) => (
                  <BsForm.Group controlId='price'>
                    <BsForm.Label className={styles.label}>Price</BsForm.Label>
                    <div className={styles.priceInput}>
                      <span className={styles.priceInputMark}>$</span>
                      <BsForm.Control
                        onChange={input.onChange}
                        type='text'
                        className={styles.control}
                      />
                    </div>
                  </BsForm.Group>
                )}
              </Field>
            </Col>
            <Col>
              <Button className={styles.submit} type='submit'>
                Send offer
              </Button>
            </Col>
          </Row>
        </form>
      )}
    />
  );
};
