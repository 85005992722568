import { useMemo, useState } from 'react';
import { ProgressBar } from '@/components/ui/bars/progress';
import styles from './CheckList.module.scss';
import { Checkbox } from '@/components/Form/Checks/Checkbox';

const initialCheckList = [
  {
    id: 1,
    name: 'Создать тематический пост',
    checked: false,
  },
  {
    id: 2,
    name: 'Привлечь 1000 уникальных переходов',
    checked: false,
  },
  {
    id: 3,
    name: 'Использоавть иллюстрации',
    checked: false,
  },
];

export const CheckList = (props) => {
  const {} = props;

  const [checkList, setCheckList] = useState(initialCheckList);

  const progressCount = useMemo(() => {
    const all = checkList.length;
    const checked = checkList?.filter(({ checked }) => checked)?.length;
    const width = (checked * 100) / all;

    return { all, checked, width };
  }, [checkList]);

  const onChange = (id) => {
    const newList = checkList?.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );

    setCheckList(newList);
  };

  return (
    <div className={styles.checklist}>
      <div className='d-flex justify-content-between align-items-center mb-3 lh-1'>
        <div className='fw-bold fs-5'>Checklist</div>
        <div className={styles.count}>
          {progressCount.checked} / {progressCount.all}
        </div>
      </div>
      <ProgressBar className='mb-3' progressWidth={progressCount.width} />
      {checkList?.map(({ id, name }) => (
        <Checkbox
          key={id}
          type='checkbox'
          label={name}
          id={`checklist-${id}`}
          onChange={() => onChange(id)}
        />
      ))}
    </div>
  );
};
