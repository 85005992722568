import { Link } from 'react-router-dom';
import styles from './ListGroup.module.scss';

const ListGroup = (props) => {
  const { children } = props;

  return <ul>{children}</ul>;
};

const ListGroupItem = (props) => {
  const { children } = props;

  return <li className={styles.item}>{children}</li>;
};

ListGroup.Item = ListGroupItem;

export { ListGroup };
