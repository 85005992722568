export const Facebook = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 386 800'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M235.378 190.897C235.378 199.084 235.378 225.77 235.378 263.683H365.728L351.595 379.881H235.378C235.378 559.081 235.378 799.217 235.378 799.217H81.1187C81.1187 799.217 81.1187 562.259 81.1187 379.881H0.739075V263.683H81.1187C81.1187 217.602 81.1187 184.834 81.1187 175.711C81.1187 132.242 77.8438 111.617 96.3141 77.9328C114.794 44.25 166.919 -0.271877 257.073 0.799998C347.25 1.91094 385.261 10.5859 385.261 10.5859L365.728 134.425C365.728 134.425 308.145 119.22 279.881 124.639C251.655 130.059 235.378 147.447 235.378 190.897Z'
        fill='#4267B2'
      />
    </svg>
  );
};
