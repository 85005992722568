import React, { Fragment, useId } from 'react';
import cn from 'classnames';
import Card from '@/components/Card';
import { Facebook } from '@/components/ui/icons';
import { Youtube } from '@/components/ui/icons';
import { Instagram } from '@/components/ui/icons';
import { useGetYoutubeAccountQuery } from '@/redux/api';
import styles from './ConnectNetworks.module.scss';

const ConnectNetworks = ({ className }) => {
  const id = useId();

  const { data: youtubeData, isSuccess: youtubeAccountSuccess } =
    useGetYoutubeAccountQuery();

  const networkLinks = [
    {
      id: 'facebook_' + id,
      href: null,
      title: 'Facebook',
      connected: false,
      icon: <Facebook />,
    },
    {
      id: 'youtube_' + id,
      href: youtubeData?.url,
      title: 'YouTube',
      connected: !youtubeData?.url && youtubeAccountSuccess,
      icon: <Youtube />,
    },
    {
      id: 'instagram_' + id,
      href: null,
      title: 'Instagram',
      connected: false,
      icon: <Instagram />,
    },
  ];

  return (
    <Card
      className={cn(styles.card, className)}
      title='Connect social Network'
      classTitle={cn('title-blue', styles.cardTitle)}
    >
      <div className={styles.section}>
        <div className={styles.btns}>
          {networkLinks?.map(({ id, href, title, connected, icon }) => (
            <Fragment key={id}>
              {connected ? (
                <div className={cn('button-stroke', styles.button)}>
                  {icon}
                  <span>Connected</span>
                </div>
              ) : (
                <a className={cn('button-stroke', styles.button)} href={href}>
                  {icon}
                  <span>{title}</span>
                </a>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default ConnectNetworks;
