export const Sprite = () => {
  return (
    <svg className='d-none' xmlns='http://www.w3.org/2000/svg'>
      <symbol id='main-logo' viewBox='0 0 183 42'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M39.9277 7.22424C39.9552 5.29779 39.1779 3.44702 37.7827 2.11714C36.4493 0.745806 34.6114 -0.0192788 32.6979 4.9503e-06H11.0062V7.57464H32.2992V17.1409H22.3385V24.8207H32.3064V34.3869H22.3385V41.9996H39.9349L39.9277 7.22424Z'
          fill='#3B3B3B'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.000680294 34.7781C-0.0263104 36.7045 0.750888 38.555 2.14559 39.8853C3.47949 41.2557 5.31731 42.0199 7.23044 41.9996H15.3729V34.4301H7.62915V24.8639H15.3729V17.1409H0.000680294V34.7781Z'
          fill='#FACC15'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M62.8443 12.9193C65.0063 12.9324 66.7568 14.6783 66.7739 16.8385V28.5983H54.7835C52.6222 28.5865 50.873 26.8387 50.8612 24.6791V18.6792H62.6306V17.0545H50.8612V12.9193H62.8443ZM62.6282 24.4607V22.3008H55.0045V24.4607H62.6282ZM84.9972 7.78584H89.1357V28.5959H77.1261C74.9659 28.5815 73.2182 26.8352 73.2038 24.6767V16.8385C73.2208 14.6811 74.967 12.9363 77.1261 12.9193H84.9972V7.78584ZM77.3687 17.0545V24.4607H84.9972V17.0545H77.3687Z'
          fill='#FACC15'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M115.319 12.9193C117.479 12.9337 119.227 14.68 119.241 16.8385V28.5983H115.132V17.0545H109.936V28.5959H105.771V17.0545H100.578V28.5959H96.4399V12.9193H115.319ZM138.339 12.9193C140.499 12.9337 142.247 14.68 142.261 16.8385V28.5983H130.278C129.233 28.6137 128.228 28.1927 127.506 27.4367C126.761 26.7135 126.345 25.7169 126.356 24.6791V18.6792H138.125V17.0545H126.356V12.9193H138.339ZM138.123 24.4607V22.3008H130.494V24.4607H138.123ZM152.702 12.9193H161.349V17.0545H152.892V28.5959H148.754V16.8361C148.743 15.7966 149.164 14.7991 149.916 14.0809C150.644 13.3255 151.653 12.9043 152.702 12.9169V12.9193ZM182.998 30.2998C182.996 32.4638 181.241 34.2176 179.075 34.219H169.554V30.055H178.859V28.5959H171.017C168.851 28.5842 167.094 26.8406 167.068 24.6767V12.9745H171.207V24.4607H178.862V12.9745H183L182.998 30.2998Z'
          fill='#3B3B3B'
        />
      </symbol>
      <symbol id='dashboard' viewBox='0 0 16 16'>
        <path d='M6.666 4c.368 0 .667.298.667.667v4h4c.368 0 .667.298.667.667 0 1.055-.313 2.086-.899 2.963s-1.419 1.561-2.394 1.964-2.047.509-3.081.304-1.985-.714-2.731-1.46-1.254-1.696-1.46-2.731-.1-2.107.304-3.081 1.087-1.807 1.964-2.394S5.611 4 6.666 4zM6 5.389a4 4 0 0 0-3.029 2.413 4 4 0 0 0 .867 4.359 4 4 0 0 0 4.359.867A4 4 0 0 0 10.61 10H6.666c-.177 0-.346-.07-.471-.195S6 9.51 6 9.333V5.389zm3.333-4.056c1.414 0 2.771.562 3.771 1.562s1.562 2.357 1.562 3.771c0 .368-.298.667-.667.667H9.333c-.177 0-.346-.07-.471-.195s-.195-.295-.195-.471V2c0-.177.07-.346.195-.471s.295-.195.471-.195zM10 2.722V6h3.277A4 4 0 0 0 10 2.722z' />
      </symbol>
      <symbol id='search' viewBox='0 0 16 16'>
        <path d='M7.333 1.333a6 6 0 0 1 6 6c0 1.417-.491 2.719-1.312 3.745h0l1.783 1.783c.26.26.26.682 0 .943s-.682.26-.943 0h0l-1.783-1.783c-1.026.821-2.328 1.312-3.745 1.312a6 6 0 1 1 0-12zm0 1.333a4.67 4.67 0 0 0-4.667 4.667A4.67 4.67 0 0 0 7.333 12 4.67 4.67 0 0 0 12 7.333a4.67 4.67 0 0 0-4.667-4.667z' />
      </symbol>
      <symbol id='account' viewBox='0 0 16 16'>
        <path d='M8 1.333A6.67 6.67 0 0 1 14.666 8a6.65 6.65 0 0 1-2.294 5.033l-.132.112A6.64 6.64 0 0 1 8 14.666a6.64 6.64 0 0 1-4.373-1.634A6.65 6.65 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 10a3.33 3.33 0 0 0-2.642 1.301c.731.418 1.57.667 2.465.697l.16.003h0H8l.172-.003c.897-.028 1.738-.278 2.47-.697A3.33 3.33 0 0 0 8 11.333zm0-8.667C5.054 2.666 2.666 5.054 2.666 8c0 1.505.623 2.864 1.625 3.833A4.66 4.66 0 0 1 8 10a4.66 4.66 0 0 1 3.708 1.833c1.002-.97 1.625-2.329 1.625-3.833 0-2.946-2.388-5.333-5.333-5.333zM8 4c1.473 0 2.667 1.194 2.667 2.667S9.472 9.333 8 9.333 5.333 8.139 5.333 6.666 6.527 4 8 4zm0 1.333c-.736 0-1.333.597-1.333 1.333S7.263 8 8 8s1.333-.597 1.333-1.333S8.736 5.333 8 5.333z' />
      </symbol>
      <symbol id='messages' viewBox='0 0 16 16'>
        <path d='M12.666 2.667a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h9.333zm.667 3.246L9.109 8.729a2 2 0 0 1-2.219 0L2.666 5.913v5.421c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V5.913zM12.666 4H3.333c-.257 0-.481.146-.592.36h0L7.63 7.619c.224.149.516.149.74 0h0l4.889-3.259c-.111-.214-.334-.36-.592-.36h0z' />
      </symbol>
      <symbol id='payments' viewBox='0 0 16 16'>
        <path d='M12.666 2.667a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h9.333zm.667 4H2.666v4.667c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V6.667zM12.666 4H3.333c-.368 0-.667.298-.667.667h0v.667h10.667v-.667c0-.368-.298-.667-.667-.667h0zM4 10c0-.368.298-.667.667-.667h4c.368 0 .667.298.667.667s-.298.667-.667.667h-4C4.298 10.667 4 10.369 4 10z' />
      </symbol>
      <symbol id='promote' viewBox='0 0 16 16'>
        <path d='M9.297 1.563l.848.722c.107.091.24.146.379.157l1.11.089a2 2 0 0 1 1.835 1.835l.089 1.11c.011.14.066.273.157.379l.722.848a2 2 0 0 1 0 2.594l-.722.848c-.091.107-.146.24-.157.379l-.089 1.11a2 2 0 0 1-1.835 1.835l-1.11.089c-.14.011-.273.066-.379.157l-.848.722a2 2 0 0 1-2.594 0l-.848-.722c-.107-.091-.24-.146-.379-.157l-1.11-.089a2 2 0 0 1-1.835-1.835l-.089-1.11c-.011-.14-.066-.273-.157-.379l-.722-.848a2 2 0 0 1 0-2.594l.722-.848c.091-.107.146-.24.157-.379l.089-1.11a2 2 0 0 1 1.835-1.835l1.11-.089c.14-.011.273-.066.379-.157l.848-.722a2 2 0 0 1 2.594 0zm-1.73 1.015L6.72 3.3a2 2 0 0 1-1.138.471l-1.11.089c-.326.026-.585.285-.612.612l-.089 1.11A2 2 0 0 1 3.3 6.72l-.722.848c-.212.249-.212.616 0 .865l.722.848a2 2 0 0 1 .471 1.138l.089 1.11c.026.326.285.586.612.612l1.11.089a2 2 0 0 1 1.138.471l.848.722c.249.212.616.212.865 0l.848-.722a2 2 0 0 1 1.138-.471l1.11-.089c.326-.026.585-.285.611-.612l.089-1.11A2 2 0 0 1 12.7 9.28l.722-.848c.212-.249.212-.616 0-.865L12.7 6.72a2 2 0 0 1-.471-1.138l-.089-1.11c-.026-.326-.285-.585-.611-.612l-1.11-.089A2 2 0 0 1 9.28 3.3l-.848-.722c-.249-.212-.616-.212-.865 0zm2.1 6.089a1 1 0 1 1 0 2 1 1 0 1 1 0-2zm.427-3.179c.283.236.321.656.085.939l-3.333 4c-.236.283-.656.321-.939.085s-.321-.656-.085-.939l3.333-4c.236-.283.656-.321.939-.085zm-3.76-.155a1 1 0 1 1 0 2 1 1 0 1 1 0-2z' />
      </symbol>
      <symbol id='close' viewBox='0 0 16 16'>
        <path
          fill='#6F767E'
          d='M4.471 3.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943L7.057 8l-3.529 3.529c-.26.26-.26.682 0 .943s.682.26.943 0L8 8.942l3.529 3.529c.26.26.682.26.943 0s.26-.682 0-.943L8.942 8l3.529-3.529c.26-.26.26-.682 0-.943s-.682-.26-.943 0L8 7.057 4.471 3.528z'
        />
      </symbol>
      <symbol id='arrow-right' viewBox='0 0 16 16'>
        <path
          fill='currentColor'
          d='M8.862 11.862c-.26.26-.26.682 0 .943s.682.26.943 0l3.862-3.862c.521-.521.521-1.365 0-1.886L9.805 3.195c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l3.195 3.195H2.667C2.298 7.333 2 7.632 2 8s.298.667.667.667h9.391l-3.195 3.195z'
        />
      </symbol>
      <symbol id='connect' viewBox='0 0 668 334'>
        <path
          d='M64 167C64 110 110.333 63.6667 167.333 63.6667H300.667V0.333344H167.333C75.3334 0.333344 0.666687 75 0.666687 167C0.666687 259 75.3334 333.667 167.333 333.667H300.667V270.333H167.333C110.333 270.333 64 224 64 167ZM200.667 200.333H467.333V133.667H200.667V200.333ZM500.667 0.333344H367.333V63.6667H500.667C557.667 63.6667 604 110 604 167C604 224 557.667 270.333 500.667 270.333H367.333V333.667H500.667C592.667 333.667 667.333 259 667.333 167C667.333 75 592.667 0.333344 500.667 0.333344Z'
          fill='currentColor'
        />
      </symbol>
      <symbol id='service-1' viewBox='0 0 50 50'>
        <path
          opacity='0.2'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z'
          fill='#F23936'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.6667 29.1667C18.6667 31.3758 20.3859 33.1834 22.5594 33.3245L22.8333 33.3333L30.1907 33.3345C29.8471 34.3049 28.9214 35 27.8333 35H22C19.2386 35 17 32.7614 17 30V20.8333C17 19.7446 17.6959 18.8185 18.6672 18.4754L18.6667 29.1667ZM33.6667 17.5V29.1667C33.6667 30.5474 32.5474 31.6667 31.1667 31.6667H22.8333C21.4526 31.6667 20.3333 30.5474 20.3333 29.1667V17.5C20.3333 16.1193 21.4526 15 22.8333 15H31.1667C32.5474 15 33.6667 16.1193 33.6667 17.5ZM22.8333 16.6667C22.3731 16.6667 22 17.0398 22 17.5V29.1667C22 29.6269 22.3731 30 22.8333 30H31.1667C31.6269 30 32 29.6269 32 29.1667V17.5C32 17.0398 31.6269 16.6667 31.1667 16.6667H22.8333Z'
          fill='#F23936'
        />
      </symbol>
      <symbol id='service-2' viewBox='0 0 50 50'>
        <path
          opacity='0.2'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z'
          fill='#45C646'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M28.5504 21.4496C26.7828 19.682 25.7886 17.2855 25.7857 14.7857C25.7857 14.5773 25.7029 14.3775 25.5556 14.2301C25.4082 14.0828 25.2084 14 25 14C24.7916 14 24.5918 14.0828 24.4444 14.2301C24.2971 14.3775 24.2143 14.5773 24.2143 14.7857C24.2114 17.2855 23.2172 19.682 21.4496 21.4496C19.682 23.2172 17.2855 24.2114 14.7857 24.2143C14.5773 24.2143 14.3775 24.2971 14.2301 24.4444C14.0828 24.5918 14 24.7916 14 25C14 25.2084 14.0828 25.4082 14.2301 25.5556C14.3775 25.7029 14.5773 25.7857 14.7857 25.7857C17.2855 25.7886 19.682 26.7828 21.4496 28.5504C23.2172 30.318 24.2114 32.7145 24.2143 35.2143C24.2143 35.4227 24.2971 35.6225 24.4444 35.7699C24.5918 35.9172 24.7916 36 25 36C25.2084 36 25.4082 35.9172 25.5556 35.7699C25.7029 35.6225 25.7857 35.4227 25.7857 35.2143C25.7886 32.7145 26.7828 30.318 28.5504 28.5504C30.318 26.7828 32.7145 25.7886 35.2143 25.7857C35.4227 25.7857 35.6225 25.7029 35.7699 25.5556C35.9172 25.4082 36 25.2084 36 25C36 24.7916 35.9172 24.5918 35.7699 24.4444C35.6225 24.2971 35.4227 24.2143 35.2143 24.2143C32.7145 24.2114 30.318 23.2172 28.5504 21.4496ZM22.5543 27.4457C21.4962 26.3877 20.2343 25.5556 18.8449 25C20.2343 24.4444 21.4962 23.6123 22.5543 22.5543C23.6123 21.4962 24.4444 20.2343 25 18.8449C25.5556 20.2343 26.3877 21.4962 27.4457 22.5543C28.5038 23.6123 29.7657 24.4444 31.1551 25C29.7657 25.5556 28.5038 26.3877 27.4457 27.4457C26.3877 28.5038 25.5556 29.7657 25 31.1551C24.4444 29.7657 23.6123 28.5038 22.5543 27.4457Z'
          fill='#45C646'
        />
      </symbol>
      <symbol id='service-3' viewBox='0 0 50 50'>
        <path
          opacity='0.2'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z'
          fill='#008EFF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29.1549 15.1426C29.1343 15.1304 29.1113 15.1242 29.089 15.1143C28.7848 14.9319 28.3917 14.9648 28.1395 15.2346L21.6877 22.1477L18.1319 18.8877C17.8974 18.6732 17.5541 18.6264 17.2713 18.7674L15.4237 19.6909C15.2068 19.7989 15.0543 20.002 15.0122 20.2403C14.97 20.4786 15.0413 20.7223 15.206 20.8986L19.0293 24.9954L15.206 29.0923C15.0413 29.2686 14.9692 29.513 15.0122 29.7506C15.0551 29.9881 15.2076 30.192 15.4237 30.3L17.2713 31.2242C17.3802 31.2787 17.4974 31.3047 17.6139 31.3047C17.7894 31.3047 17.9641 31.2442 18.1051 31.1269L21.8441 28.011L28.1395 34.7563C28.2905 34.918 28.4951 35 28.6997 35C28.84 35 28.9749 34.9487 29.0982 34.872C29.1166 34.8628 29.1366 34.859 29.1542 34.8483L33.7522 32.0894C33.9829 31.9515 34.1247 31.7017 34.1247 31.4327V18.5582C34.1247 18.2892 33.9829 18.0394 33.753 17.9015L29.1549 15.1426ZM17.0261 29.3881L20.0769 26.1189L20.7957 26.8891L17.5081 29.6287L17.0261 29.3881ZM17.0261 20.6028L17.4851 20.3737L27.9939 30.0073V32.3538L17.0261 20.6028ZM25.1133 25.2867L27.9939 22.8865V27.9275L25.1133 25.2867ZM23.973 24.2414L22.8181 23.1823L27.9939 17.6371V20.8909L23.973 24.2414ZM29.5266 32.8374V17.1527L32.592 18.992V30.9982L29.5266 32.8374Z'
          fill='#008EFF'
        />
      </symbol>
      <symbol id='subscribers' viewBox='0 0 667 501'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M100.667 150.667C100.667 67.824 167.824 0.666687 250.667 0.666687C333.51 0.666687 400.667 67.824 400.667 150.667C400.667 233.51 333.51 300.667 250.667 300.667C167.824 300.667 100.667 233.51 100.667 150.667Z'
          fill='currentColor'
        />
        <path
          d='M412.917 236.107C411.4 238.98 412.043 242.563 414.653 244.497C434.04 258.847 458.03 267.333 484 267.333C548.433 267.333 600.667 215.1 600.667 150.667C600.667 86.2333 548.433 34 484 34C458.03 34 434.04 42.4867 414.653 56.8377C412.043 58.771 411.4 62.3527 412.917 65.2263C426.38 90.7397 434 119.813 434 150.667C434 181.52 426.38 210.593 412.917 236.107Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.7051 357.31C129.784 339.48 183.682 334 250.665 334C317.707 334 371.643 339.49 412.737 357.36C457.5 376.823 484.707 409.977 498.967 456.787C505.65 478.727 489.17 500.667 466.46 500.667H34.9337C12.1964 500.667 -4.31827 478.693 2.38573 456.713C16.6647 409.897 43.9127 376.753 88.7051 357.31Z'
          fill='currentColor'
        />
        <path
          d='M427.95 301.88C414.15 302.737 413.34 321.277 426.02 326.79C460.767 341.9 486.93 364.143 505.693 392.533C521.09 415.83 545 434 572.923 434H632.277C655.85 434 673.05 410.577 664.757 387.693C664.28 386.377 663.783 385.07 663.27 383.773C651.857 354.953 632.293 333.737 603.327 320.213C576.13 307.517 542.083 302.277 501.973 300.693L501.313 300.667H500.657C477.03 300.667 452.353 300.363 427.95 301.88Z'
          fill='currentColor'
        />
      </symbol>
      <symbol id='user-ok' viewBox='0 0 600 668'>
        <path
          fill='currentColor'
          d='M366.667 576.857L426.667 634L566.667 500.667M33.3334 634C33.3334 505.133 137.8 400.667 266.667 400.667C316.24 400.667 362.207 416.127 400 442.49M400 167.333C400 240.971 340.303 300.667 266.667 300.667C193.029 300.667 133.333 240.971 133.333 167.333C133.333 93.6953 193.029 34 266.667 34C340.303 34 400 93.6953 400 167.333Z'
          stroke='currentColor'
          strokeWidth='66.6667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </symbol>
      <symbol id='rating-star' viewBox='0 0 601 572'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M300.012 0.849976L370.837 218.825H600.025L414.612 353.537L485.437 571.512L300.012 436.8L114.6 571.512L185.425 353.537L0 218.825H229.187L300.012 0.849976Z'
          fill='currentColor'
        />
      </symbol>
    </svg>
  );
};
