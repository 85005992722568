import cn from 'classnames';
import { useMemo } from 'react';

export const SectionHeader = (props) => {
  const { title, align, children } = props;

  const getAlignStyle = useMemo(
    () => (value) => {
      switch (value) {
        case 'center':
          return 'text-center';
        case 'right':
          return 'text-end';
        default:
          return '';
      }
    },
    [align]
  );

  console.log(getAlignStyle);

  return (
    <>
      <h2
        className={cn(
          'text-uppercase text-primary fw-normal fs-6 mb-3',
          getAlignStyle(align)
        )}
      >
        {title}
      </h2>
      <h3 className={cn('fs-1 mb-5', getAlignStyle(align))}>{children}</h3>
    </>
  );
};
