import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from '../../../../Icon';
import styles from './User.module.scss';
import { useLogoutMutation } from '../../../../../redux/api';

const items = [
  {
    menu: [
      {
        title: 'Profile',
        url: '/shop',
      },
      {
        title: 'Account settings',
        url: '/settings',
      },
    ],
  },
  // {
  //     menu: [
  //         {
  //             title: "Upgrade to Pro",
  //             icon: "leaderboard",
  //             color: true,
  //             url: "/upgrade-to-pro",
  //         },
  //     ],
  // },
];

const User = ({ className }) => {
  const [logout] = useLogoutMutation();

  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();

  const logoutHandler = async () => {
    try {
      await logout().unwrap();
    } catch (error) {}
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(true)}>
          <img src='/images/content/avatar.jpg' alt='Avatar' />
        </button>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.color]: x.color,
                      [styles.active]: pathname === x.url,
                    })}
                    to={x.url}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size='24' />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
          <Link className={styles.item} onClick={logoutHandler}>
            <div className={styles.icon}>
              <Icon name='exit' size='20' />
            </div>
            <div className={styles.details}>
              <div className={styles.title}>Log out</div>
            </div>
          </Link>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
