import React from 'react';
import TooltipGlodal from '../../components/TooltipGlodal';
import Overview from './Overview';
import PayoutHistory from './PayoutHistory';
import { Dashboard } from '@/components/Layouts';
import styles from './Payouts.module.scss';

export const Payouts = () => {
  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        <PayoutHistory />
      </div>
      <TooltipGlodal />
    </>
  );
};

export const LayoutedPayouts = () => {
  return (
    <Dashboard>
      <Payouts />
    </Dashboard>
  );
};
