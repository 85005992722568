import { Navigate, useSearchParams } from 'react-router-dom';
import { useGoogleLoginMutation } from '../../redux/api';
import { useEffect } from 'react';

export const Google = () => {
  const [searchParams] = useSearchParams();

  const [googleLogin, { isSuccess, isError }] = useGoogleLoginMutation();

  useEffect(() => {
    const code = searchParams.get('code');

    if (code) {
      googleLogin({ code });
    }
  }, [searchParams]);

  if (isSuccess) return <Navigate to='/search' />;
  if (isError) return 'Login is failure';

  return null;
};
