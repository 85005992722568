import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button } from 'react-bootstrap';
import TextInput from '@/components/TextInput';
import { object, string, ref } from 'yup';
import { setIn } from 'final-form';
import { useSignupMutation } from '@/redux/api';
import cn from 'classnames';
import styles from './SignUpForm.module.scss';

const signupSchema = object({
  country: string().required('Field is required'),
  email: string().email('Must be a valid email'),
  password1: string()
    .required('Field is required')
    .min(8, 'Must be at least 8 characters long'),
  password2: string()
    .oneOf([ref('password1'), null], 'Passwords must match')
    .required('Field is required'),
});

const validateFormValues = (schema) => async (values) => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  try {
    await schema.validateSync(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export const SignUpForm = () => {
  const [signup, { isError, error }] = useSignupMutation();

  const [verify, setVerify] = useState(false);

  const onSubmit = async (values) => {
    try {
      await signup(values).unwrap();
      setVerify(true);
    } catch (err) {
      return err.data;
    }
  };

  if (verify)
    return (
      <>
        <div className={styles.info}>
          We just send you a verify link. Check your inbox to get them.
        </div>
        <Button className={styles.backBtn} onClick={() => setVerify(false)}>
          <svg width='16' height='16'>
            <use xlinkHref='#arrow-right'></use>
          </svg>{' '}
          Back
        </Button>
      </>
    );

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateFormValues(signupSchema)}
      render={({ handleSubmit, reset }) => (
        <form
          className={styles.entry}
          onSubmit={(event) => {
            handleSubmit(event)?.then(() => {
              reset();
            });
          }}
        >
          {/* <div className={styles.head}>
            <div className={styles.info}>
              Before we start, please enter your current location
            </div>
          </div> */}
          <div className={styles.body}>
            <div className={styles.info}>
              Before we start, please enter your current location
            </div>
            <Field name='country'>
              {({ input, meta }) => (
                <TextInput
                  className={styles.field}
                  label='Country/Area of Residence'
                  type='text'
                  placeholder='United States'
                  icon='pointer'
                  id='country'
                  onChange={input.onChange}
                  meta={meta}
                />
              )}
            </Field>
            <Field name='email'>
              {({ input, meta }) => (
                <TextInput
                  className={styles.field}
                  label='Email'
                  type='text'
                  placeholder='henry.richardson@gmail.com'
                  icon='mail'
                  id='email'
                  onChange={input.onChange}
                  meta={meta}
                />
              )}
            </Field>
            <Field name='password1'>
              {({ input, meta }) => (
                <TextInput
                  className={styles.field}
                  label='New password'
                  id='new-password'
                  type='password'
                  tooltip='Maximum 100 characters. No HTML or emoji allowed'
                  icon='lock'
                  onChange={input.onChange}
                  meta={meta}
                />
              )}
            </Field>
            <Field name='password2'>
              {({ input, meta }) => (
                <TextInput
                  className={styles.field}
                  label='Confirm new password'
                  id='confirm-password'
                  type='password'
                  tooltip='Maximum 100 characters. No HTML or emoji allowed'
                  icon='lock'
                  onChange={input.onChange}
                  meta={meta}
                />
              )}
            </Field>

            <button type='submit' className={cn('button', styles.button)}>
              Create account
            </button>
            <div className={styles.note}>
              This site is protected by reCAPTCHA and the Google Privacy Policy.
            </div>
          </div>
        </form>
      )}
    />
  );
};
