import { useMemo } from 'react';
import { RoundedAvatar } from '@/components/ui/avatars';
import dayjs from 'dayjs';
import cn from 'classnames';
import styles from './HistoryItem.module.scss';

export const HistoryItem = (props) => {
  const { from, name, text, date } = props;

  const getItemStyle = useMemo(
    () => (value) => {
      switch (value) {
        case 'me':
          return styles.myMessage;
        case 'someone':
          return styles.someoneMessage;
        default:
          return styles.myMessage;
      }
    },
    [from]
  );

  return (
    <div className={cn(styles.item, getItemStyle(from))}>
      <div className={styles.avatar}>
        <RoundedAvatar size='small' />
      </div>
      <div className={styles.body}>
        <div className={styles.info}>
          <div className={styles.name}>{name}</div>
          <div className={styles.date}>{dayjs(date).format('h:mm A')}</div>
        </div>
        <div className={styles.message}>{text}</div>
      </div>
    </div>
  );
};
