import React, { useState, useRef, useMemo, useEffect } from 'react';
import cn from 'classnames';
import styles from './Settings.module.scss';
import TooltipGlodal from '../../components/TooltipGlodal';
import Dropdown from '../../components/Dropdown';
import ProfileInformation from './ProfileInformation';
import Login from './Login';
import Notifications from './Notifications';
import Payment from './Payment';
import { SocialNetworks } from './SocialNetworks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dashboard } from '../../components/Layouts';

export const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigation = [
    {
      href: '#profile',
      title: 'Profile',
      component: <ProfileInformation />,
    },
    {
      href: '#security',
      title: 'Security',
      component: <Login />,
    },
    {
      href: '#networks',
      title: 'Social Networks',
      component: <SocialNetworks />,
    },
    {
      href: '#notifications',
      title: 'Notifications',
      component: <Notifications />,
    },
    { href: '#payment', title: 'Payment', component: <Payment /> },
  ];
  const options = useMemo(
    () => navigation.map(({ title }) => title),
    [navigation]
  );

  const [activeTab, setActiveTab] = useState();

  const [dropdownValue, setDropdownValue] = useState();

  const onClickHandler = (evt) => {
    const { id } = evt.target;
    setActiveTab(id);
  };

  useEffect(() => {
    if (activeTab) {
      const id = activeTab.replace('#', '');
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
      navigate(activeTab, { replace: true });
    }
  }, [activeTab]);

  useEffect(() => {
    if (dropdownValue) {
      const id = navigation.find(({ title }) => title === dropdownValue).href;
      setActiveTab(id);
    }
  }, [dropdownValue]);

  useEffect(() => {
    const href =
      navigation.find(({ href }) => href === location.hash)?.href ||
      navigation[0].href;

    const value =
      navigation.find(({ href }) => href === location.hash)?.title ||
      navigation[0].title;

    setActiveTab(href);
    setDropdownValue(value);
  }, [location.hash]);

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation.map(({ title, href }) => (
            <button
              id={href}
              className={cn(styles.button, {
                [styles.active]: activeTab === href,
              })}
              key={title}
              onClick={onClickHandler}
            >
              {title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={dropdownValue}
            setValue={setDropdownValue}
            options={options}
          />
          <div className={styles.list}>
            {navigation?.map(({ title, href, component }) => (
              <div
                key={title}
                className={cn(styles.item, {
                  [styles.active]: activeTab === href,
                })}
              >
                <div id={href.replace('#', '')} className={styles.anchor} />
                {component}
              </div>
            ))}
          </div>
          <button className={cn('button', styles.button)}>Save</button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export const LayoutedSettings = () => {
  return (
    <Dashboard>
      <Settings />
    </Dashboard>
  );
};
