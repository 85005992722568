import React, { useEffect, useState } from 'react';

const OAuth = () => {
  // const [state, setState] = useState('loading');
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  // useEffect(() => {
  //   if (!userInfo) {
  //     navigate('/signin');
  //   }
  // }, [userInfo]);

  // useEffect(() => {
  //   const authService = new AuthService();
  //   const params = new URLSearchParams(window.location.search);
  //   authService
  //     .finishGoogleOAuth(
  //       userInfo.access,
  //       window.location.href,
  //       params.get('state')
  //     )
  //     .then((res) => {
  //       setState('success');
  //     })
  //     .catch((err) => {
  //       console.error('Error:', err);
  //       setState('error');
  //     });
  // }, [userInfo]);

  // if (state === 'error') {
  //   return (
  //     <>
  //       <h1>Error in fetched data</h1>
  //       <a href='/'>return to index page</a>
  //     </>
  //   );
  // } else if (state === 'loading') {
  //   return <h1>Checking data</h1>;
  // }

  return (
    <>
      <h1>redirect to index page</h1>
      <a href='/'>return to index page</a>
    </>
  );
};

export default OAuth;
