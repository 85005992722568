import { useId } from 'react';
import styles from './FileUpload.module.scss';
import cn from 'classnames';

export const FileUpload = (props) => {
  const { title, rowClassName, titleClassName, onChange, children } = props;

  const id = useId();

  return (
    <div className={styles.upload}>
      {title && <div className={cn(styles.title, titleClassName)}>{title}</div>}
      <div className={rowClassName}>
        <div className={styles.btn}>
          <input
            id={id + 'file'}
            type='file'
            onChange={(evt) => onChange(evt.target.files[0])}
          />
          <label htmlFor={id + 'file'}>Upload file</label>
        </div>
        <div className={styles.descr}>{children}</div>
      </div>
    </div>
  );
};
