import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import Dropdown from '@/components/Dropdown';
import Loader from '@/components/Loader';
import Item from './Item';
import { useGetYoutubeAccountQuery, useGetYoutubeListQuery } from '@/redux/api';
import { useSearchParams } from 'react-router-dom';
import styles from './List.module.scss';

const intervals = ['Recent', 'New', 'This year'];

const actions = [
  {
    title: 'Mark as read',
    icon: 'check',
    action: () => console.log('Mark as read'),
  },
  {
    title: 'Go setting',
    icon: 'setting',
    action: () => console.log('Go setting'),
  },
];

const navigation = ['Bloggers', 'Web Sites', 'TV-Radio'];
const options = ['Best', 'New'];

const List = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: youtubeList } = useGetYoutubeListQuery();

  const { data: youtubeData, isSuccess: youtubeAccountSuccess } =
    useGetYoutubeAccountQuery();

  const [searchParams, setSearchParams] = useSearchParams();
  const networkValues = searchParams.getAll('network');

  const filteredList = useMemo(() => {
    if (!networkValues.length) {
      return youtubeList;
    }
    return youtubeList?.filter(({ from }) => networkValues.includes(from));
  }, [networkValues]);

  return (
    <>
      <div className={styles.head}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={sorting}
          setValue={setSorting}
          options={options}
          small
        />
      </div>
      <div className={styles.notifications}>
        <div className={styles.list}>
          {!youtubeData?.url && youtubeAccountSuccess
            ? filteredList?.map((item) => <Item key={item.login} {...item} />)
            : null}
        </div>
        <div className={styles.foot}>
          <button className={cn('button-stroke button-small', styles.button)}>
            <Loader className={styles.loader} />
            <span>Load more</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default List;
