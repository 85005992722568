import Item from '../Item';
import { NetworkItem } from './NetworkItem';
import { Facebook, Instagram, Youtube, Twitter } from '@/components/ui/icons';
import { useGetYoutubeAccountQuery } from '@/redux/api';
import styles from './SocialNetworks.module.scss';
import { useId, useMemo } from 'react';

export const SocialNetworks = () => {
  const id = useId();

  const { data: youtubeData, isSuccess: youtubeAccountSuccess } =
    useGetYoutubeAccountQuery();

  const socialNetworks = useMemo(
    () => [
      {
        id: 'facebook_' + id,
        title: 'Facebook',
        icon: <Facebook />,
        account: null,
        href: null,
      },
      {
        id: 'twitter_' + id,
        title: 'Twitter',
        icon: <Twitter />,
        account: null,
        href: null,
      },
      {
        id: 'instagram_' + id,
        title: 'Instagram',
        icon: <Instagram />,
        account: null,
        href: null,
      },
      {
        id: 'youtube_' + id,
        title: 'YouTube',
        icon: <Youtube />,
        account:
          !youtubeData?.url && youtubeAccountSuccess
            ? youtubeData[0]?.snippet?.customUrl
            : null,
        href: youtubeData?.url,
      },
    ],
    [youtubeData]
  );

  return (
    <Item title='Social Networks'>
      <ul>
        {socialNetworks?.map((item) => (
          <NetworkItem key={item.id} {...item} className={styles.networkItem} />
        ))}
      </ul>
    </Item>
  );
};
