import { Dashboard } from '../../components/Layouts';
import { OfferCard } from './OfferCard';

const offers = [
  {
    id: 1,
    offer: '48096',
    price: 1500,
    body: 'Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries',
    date: '14 Feb 2023 20:52',
    progress: '2/3',
    deadline: '7 days 1:19',
    variant: 'success',
  },
  {
    id: 2,
    offer: '48097',
    price: 2000,
    body: 'Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries',
    date: '20 Feb 2023 20:52',
    progress: '2/3',
    deadline: '3 days 3:15',
    variant: 'warning',
  },
  {
    id: 3,
    offer: '48098',
    price: 900,
    body: 'Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries. Нужно сделать рекламу в сториз или рилз детского надувного бассейна со ссылкой на Wildberries',
    date: '3 Feb 2023 20:52',
    progress: '1/3',
    deadline: '2 days 2:01',
    variant: 'danger',
  },
];

export const Offers = () => {
  return (
    <div>
      {/* <div className='mb-4'>Actions</div> */}
      <div>
        {offers?.map(({ id, ...offerProps }) => (
          <OfferCard key={id} {...offerProps} />
        ))}
      </div>
    </div>
  );
};

export const LayoutedOffers = () => {
  return (
    <Dashboard title='My offers'>
      <Offers />
    </Dashboard>
  );
};
