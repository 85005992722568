import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Card from '@/components/Card';
import { RoundedAvatar } from '@/components/ui/avatars/RoundedAvatar';
import { Youtube } from '@/components/ui/icons/';
import { BorderedBadge } from '@/components/ui/badges';
import { Stats } from '@/components/Stats';
import cn from 'classnames';
import styles from './Item.module.scss';

const Item = (props) => {
  const { title, login, description, stats, avatar, className } = props;

  return (
    <Card className={cn(styles.item, className)}>
      <div className={styles.avatar}>
        <RoundedAvatar src={avatar} />
      </div>
      <div className={styles.network}>
        <Youtube /> {title}
      </div>
      <BorderedBadge className={styles.price}>$ 1 355 post</BorderedBadge>
      <div className={styles.posted}>Posted 1 day ago</div>
      <div className={styles.tags}>
        <div className={styles.tag}>London</div>
        <div className={styles.tag}>Travel-Time</div>
      </div>

      <p className={styles.descr}>{description}</p>
      <div className={styles.stats}>
        <Stats
          icon={
            <svg width='20' height='20'>
              <use xlinkHref='#subscribers'></use>
            </svg>
          }
          className={styles.stat}
          value={stats?.subscribers}
          prop={'Subscribers'}
        />
        <Stats
          icon={
            <svg width='20' height='20'>
              <use xlinkHref='#user-ok'></use>
            </svg>
          }
          className={styles.stat}
          value={'6.75 %'}
          prop={'Engagement rating'}
        />
        <Stats
          icon={
            <svg width='20' height='20'>
              <use xlinkHref='#rating-star'></use>
            </svg>
          }
          className={styles.stat}
          value={'9.75 / 9'}
          prop={'Rating / Deals'}
        />
      </div>
      <Link className={styles.link} to={`/search/${login}`} />
    </Card>
  );
};

export default Item;
