import cn from 'classnames';
import styles from './DefaultBadge.module.scss';

export const DefaultBadge = (props) => {
  const { className, variant, children } = props;

  return (
    <div className={cn(styles.bage, styles[variant], className)}>
      {children}
    </div>
  );
};
