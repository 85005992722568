import cn from 'classnames';
import styles from './NetworkItem.module.scss';

export const NetworkItem = (props) => {
  const { icon, title, account, href, className } = props;

  return (
    <li className={cn(styles.item, className)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.link}>{account || 'Not connected'}</div>
      {!account && (
        <a href={href} className={styles.btn}>
          <svg width='16' height='16'>
            <use xlinkHref='#connect'></use>
          </svg>
          Connect
        </a>
      )}
    </li>
  );
};
