import { Col, Container, Row } from 'react-bootstrap';
import cn from 'classnames';
import styles from './Advatages.module.scss';

export const Advantages = () => {
  return (
    <section className={cn(styles.section, 'py-5')}>
      <Container className='pt-3'>
        <Row className='align-items-center'>
          <Col xs={12} md={6} xl={5} xxl={4} className='text-white mb-5'>
            <h2 className='mb-5'>
              The right ad to the right person, at the right time!
            </h2>
            <p className='mb-5 text-light'>
              AdMary allows you to deliver your ads straight to the most
              valuable potential customers across multiple resources, control
              costs, manage marketing campaigns, and much more.
            </p>
            <Row>
              <Col>
                <Row className='align-items-center'>
                  <Col xs={12} lg='auto' className='fs-1'>
                    12K+
                  </Col>
                  <Col>
                    Blogers
                    <br />& chanels
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className='align-items-center'>
                  <Col xs={12} lg='auto' className='fs-1'>
                    1947
                  </Col>
                  <Col>
                    Adv
                    <br />
                    Joined
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className='text-end'>
            <picture>
              <img src='/images/advantages.png' className='img-fluid' />
            </picture>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
