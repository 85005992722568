import { Main } from '@/components/Layouts';
import { Button, Col, Container, Row } from 'react-bootstrap';

export const Privacy = () => {
  document.title = 'ADMARY PRIVACY POLICY';
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      'content',
      'This Privacy Policy describes the personal information we may obtain online across all of our services and how we may use and disclose that information.'
    );

  return (
    <Main>
      <section className='pt-5 bg-white'>
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12} className='mb-5 pt-5'>
              <h1 className='mb-4'>ADMARY PRIVACY POLICY FOR THE</h1>
              <h3 className='mb-3'>Jan 1, 2024</h3>
              <p className='mb-4 text-secondary'>
                AdMary knows how important privacy is to our customers. This
                Privacy Policy describes the personal information we may obtain
                online across all of our services and how we may use and
                disclose that information. We also explain the choices available
                to you regarding our use of the information, the steps we take
                to safeguard the information, and how you may contact us at
                admaryinfo@gmail.com if you have any questions about our privacy
                practices.
              </p>
              <h3 className='mb-3'>Information We Obtain</h3>
              <p className='mb-4 text-secondary'></p>
              <h2>
                <b>ADMARY PRIVACY POLICY FOR THE</b>
              </h2>
              <h3>
                <b>Jan 1, 2024</b>
              </h3>
              <p>
                AdMary knows how important privacy is to our customers. This
                Privacy Policy describes the personal information we may
                obtain online across all of our services and how we may use
                and disclose that information. We also explain the choices
                available to you regarding our use of the information, the
                steps we take to safeguard the information, and how you may
                contact us at admaryinfo@gmail.com if you have any questions
                about our privacy practices.
              </p>
              <h3>
                <b>Information We Obtain</b>
              </h3>
              <p>
                We may obtain the following personal information about you
                when you interact with us, including but not limited to when
                you create the AdMary Account, register for or use a Service,
                contact Customer Support, or while attending an event:
              </p>
              <ul>
                <li>
                  contact information (including your name, email address,
                  postal address, and phone number);
                </li>
                <li>
                  payment card information (including your name, card number,
                  expiration date, and security code);
                </li>
                <li>
                  user coupon or gift card number;
                </li>
                <li>
                  demographic data (e.g., gender and age range);
                </li>
                <li>
                  information stored in or associated with your AdMary Account
                  (including your AdMary Account profile, ID, username, and
                  password);
                </li>
                <li>
                  username and password for participating third-party apps,
                  features, or services;
                </li>
                <li>
                  transcripts of chat sessions, text messages, emails, and
                  other communications when you communicate with us using
                  these methods;
                </li>
                <li>
                  information about services you purchase, obtain, or
                  consider; your location data.
                </li>
              </ul>
              <p>
                We may obtain certain information about you from publicly or
                commercially available sources and from third parties who
                perform services for us. We also may obtain other information
                about you, your devices, and your use of the Services, in ways
                we describe at the time we collect the information or
                otherwise with your consent.
              </p>
              <h3>
                Information We Collect Automatically
              </h3>
              <p>
                Our Services collect some data automatically when you use the
                Services. We may obtain information by automated means such as
                through browser cookies, pixels, web server logs, web beacons,
                and other technologies. Among other purposes, these
                technologies help us
              </p>
              <p>
                (1) remember your information so you will not have to re-enter
                it,
              </p>
              <p>
                (2) track and understand how you use and interact with the
                Services,
              </p>
              <p>
                (3) tailor the Services around your preferences,
              </p>
              <p>
                (4) manage and measure the usability of the Services,
              </p>
              <p>
                (5) understand the effectiveness of our communications, and
              </p>
              <p>
                (6) otherwise enhance the Services.
              </p>
              <p>
                Information we may collect automatically includes information
                about
              </p>
              <ul>
                <li>
                  your device, IP address, log information, device model,
                  hardware model, IMEI number, serial number, subscription
                  information, device settings, connections to other devices,
                  web browser characteristics, app usage information, sales
                  code, access code, current software version, subscription
                  information, and randomized, non-persistent and resettable
                  device identifiers, such as Personalized Service ID (or
                  PSID), and advertising IDs, including Google Ad ID;
                </li>
                <li>
                  your use of the Services, including clickstream data, your
                  interactions with the Services (such as the web pages you
                  visit, search terms, services and features you use,
                  download, or purchase), the pages that lead or refer you to
                  the Services, how you use the Services, and dates and times
                  of use of the Services;
                </li>
                <li>
                  your use of third-party websites, apps and features that are
                  connected to certain Services.
                </li>
              </ul>
              <p>
                Your browser may tell you how to be notified when you receive
                certain types of cookies or how to restrict or disable certain
                types of cookies. You can find out how to do this for your
                particular browser by clicking “help” on your browser’s menu
                or visiting
                <a href='http://www.allaboutcookies.org/'>
                  www.allaboutcookies.org
                </a>
                . For mobile devices, you can manage how your device and
                browser share certain device data by adjusting the privacy and
                security settings on your device. Please note that without
                cookies, you may not be able to use or benefit from all of the
                features of our Services.
              </p>
              <h3>
                <b>Third-Party Web Analytics Services</b>
              </h3>
              <p>
                Through certain Services, we may collect personal information
                about your online activities on websites and connected devices
                over time and across third-party websites, devices, apps, and
                other online features and services. We may use third-party
                analytics services on the Services, such as Google Analytics
                and Firebase Analytics. The information we obtain may be
                disclosed to or collected directly by these providers and
                other relevant third parties who use the information, for
                example, to evaluate use of the Services or help administer
                the Services. To learn more about Google Analytics, please
                visit
                <a href='https://www.google.com/policies/privacy/partners/'>
                  https://www.google.com/policies/privacy/partners/
                </a>
                . To learn more about Firebase Analytics, please visit
                <a href='https://firebase.google.com/policies/analytics/'>
                  https://firebase.google.com/policies/analytics/
                </a>
                .
              </p>
              <h3>
                <b>Online Tracking And Interest-Based Advertising</b>
              </h3>
              <p>
                Through certain Services, both we and various third parties
                may collect information about your online activities to
                provide you with advertising about products and services
                tailored to your individual interests.
              </p>
              <p>
                <i>AdMary Ads</i>
              </p>
              <p>
                Our AdMary Ads service is designed to serve advertisements
                that are customized to users’ interests on AdMary and
                third-party platforms, devices, websites, and apps. AdMary Ads
                may deliver customized ads based on your demographic
                characteristics, preferences, choices, and interests by
                collecting certain information about your online activities as
                specified in this Privacy Policy.
              </p>
              <p>
                <i>Ad networks</i>
              </p>
              <p>
                Because AdMary participates in various advertising networks,
                you may also see our ads on other websites or mobile apps. Ad
                networks allow us to target our messaging to users considering
                demographic data, users’ inferred interests, and browsing
                context. These networks can track users’ online activities
                over time by collecting information through automated means,
                including through the use of browser cookies, web beacons,
                pixels, device identifiers, server logs, and other similar
                technologies. The networks use this information to show ads
                that may be tailored to individuals’ interests, to track
                users’ browsers or devices across multiple websites and apps,
                and to build a profile of users’ online browsing and app usage
                activities. The information our ad networks may collect
                includes data about users’ visits to websites and apps that
                participate in the relevant ad networks, such as the pages or
                ads viewed and the actions taken on the websites or apps. This
                data collection takes place both on certain Services and on
                third-party websites and apps that participate in the ad
                networks. This process also helps us track the effectiveness
                of our marketing efforts.
              </p>
              <h3>
                <b>How We Use the Information We Obtain</b>
              </h3>
              <p>
                We may use the personal information we obtain to:
              </p>
              <ul>
                <li>
                  provide and enhance our Services and third-party
                  services,managing our financing programs, and improving and
                  customizing your experience within the Services;
                </li>
                <li>
                  provide ads, which may include targeted (or interest-based)
                  ads delivered on your AdMary device or within certain
                  AdMary-branded apps (where targeted or interest-based ads
                  are available, if you turn off targeted or interest-based
                  ads, you will receive generic ads);
                </li>
                <li>
                  support our marketing activities and sales initiatives, and
                  provide you with product and service recommendations;
                </li>
                <li>
                  operate, evaluate, and improve our business, including
                  developing new products and services, managing our
                  communications, analyzing our Services and customer base,
                  conducting market research, aggregating and anonymizing
                  data, performing data analytics, and undertaking accounting,
                  auditing, and other internal functions;
                </li>
                <li>
                  protect against, identify, and prevent fraud and other
                  criminal activity, claims and other liabilities; and
                </li>
                <li>
                  comply with and enforce applicable legal requirements,
                  relevant industry standards, and our policies, including
                  this Privacy Policy and the applicable Terms of Use for a
                  Service.
                </li>
              </ul>
              <p>
                We may combine information we obtain about you for the
                purposes described above. We also may use the information we
                obtain in other ways for which we provide specific notice at
                the time of collection or otherwise with your consent.
              </p>
              <h3>
                <b>Information Sharing</b>
              </h3>
              <p>
                We may share your personal information with our subsidiaries
                and affiliates and with service providers who perform services
                for us. We do not authorize our service providers to use or
                disclose the information except as necessary to perform
                services on our behalf or to comply with legal requirements.
                In addition, we may share your personal information with our
                business partners, such as financing partners when you apply
                for and use credit or financing programs, and third parties
                who operate apps and services that connect with certain
                Services. This kind of sharing may be considered a “sale”
                under certain state privacy laws.
              </p>
              <p>
                We may share personal information we collect through the
                Services if you ask us to do so or otherwise with your
                consent. We also may disclose information about you in other
                circumstances, including:
              </p>
              <ul>
                <li>
                  to law enforcement authorities, government or public
                  agencies or officials, regulators, and/or any other person
                  or entity with appropriate legal authority or justification
                  for receipt of such information, if required or permitted to
                  do so by law or legal process;
                </li>
                <li>
                  when we believe disclosure is necessary or appropriate to
                  prevent physical harm or financial loss, or in connection
                  with an investigation of suspected or actual fraudulent or
                  illegal activity; or
                </li>
                <li>
                  in the event we may or do sell or transfer all or a portion
                  of our business or assets (including in the event of a
                  merger, acquisition, joint venture, reorganization,
                  divestiture, dissolution, or liquidation).
                </li>
              </ul>
              <h3>
                <b>Links to Other Websites, Apps, And Features</b>
              </h3>
              <p>
                The Services may provide the ability to connect to other
                websites, apps, and other features, which may operate
                independently from us and may have their own privacy notices
                or policies. We strongly suggest you review them. To the
                extent any linked website, device, app, or other feature is
                not owned or controlled by us, we are not responsible for its
                content, use, or privacy practices.
              </p>
              <h3>
                <b>Your Rights and Choices</b>
              </h3>
              <p>
                We offer you certain choices in connection with the personal
                information we obtain about you.
              </p>
              <p>
                To update your preferences, limit the communications you
                receive from us, or submit a request, please contact us at
                admaryinfo@gmail.com.
              </p>
              <p>
                The Services may offer choices related to the collection,
                deletion, and sharing of certain information and
                communications about products, services, and promotions that
                are unique to specific Services. You can access the relevant
                settings of each Service to learn about choices that may be
                available for that Service. If you decline to allow the
                Services to collect, store, or share certain information, you
                may not be able to enjoy full use of all of the features
                available through the Services.
              </p>
              <p>
                Subject to applicable law and to the extent applicable to each
                Service, you may (1) request access to, or correction or
                deletion of, the personal information we maintain about you;
                (2) request that we limit our use and disclosure of your
                sensitive personal information; or (3) opt out of the (a) sale
                of your personal information, (b) sharing of your personal
                information for cross-context behavioral advertising purposes,
                or (c) processing of your personal information for targeted
                advertising purposes. In addition, subject to applicable law,
                you may receive, in a structured, commonly used and
                machine-readable format, certain of your personal information
                that you have provided to us. Subject to applicable law, you
                may have the right to have this information transmitted to
                another company, where it is technically feasible.
              </p>
              <h3>
                <b>How to Submit a Request</b>:
              </h3>
              <p>
                To submit an access, deletion or correction request, please
                send us an email. You may choose to opt-out of targeted
                advertising on AdMary.com by turning on an opt-out preference
                signal through your browser. You will need to turn it on for
                each browser and each device you use. We do not respond to
                browser-based signals that do not meet applicable state law
                requirements, which may include older Do Not Track signals.
              </p>
              <h3>
                <b>How We Protect Personal Information</b>
              </h3>
              <p>
                We maintain safeguards designed to protect the personal
                information we obtain through the Services.
              </p>
              <h3>
                <b>Children’s Personal Information</b>
              </h3>
              <p>
                Unless otherwise specified, the Services are designed for a
                general audience and are not directed at children. In
                connection with the Services, we do not knowingly solicit or
                collect personal information from children under the age of 13
                without parental consent. If we learn that we have collected
                personal information from a child under age 13 without
                parental consent, we will either seek parental consent or
                promptly delete that information.
              </p>
              <h3>
                <b>Updates To This Privacy Policy</b>
              </h3>
              <p>
                We may update this Privacy Policy from time to time and
                without prior notice to you to reflect changes in our personal
                information practices with respect to the Services. We will
                indicate at the top of the policy when it was most recently
                updated.
              </p>
              <h3>
                <b>How To Contact Us</b>
              </h3>
              <p>
                If you would like to make a privacy request, update your
                preferences, or have a privacy concern, you may contact the
                AdMary at admaryinfo@gmail.com.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Main>
  );
};

export const LayoutedHome = () => {
  return (
    <Main>
      <Privacy />
    </Main>
  );
};
