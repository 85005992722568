import { TaskCard } from '../TaskCard';

export const List = (props) => {
  const { tasks } = props;
  return (
    <>
      {tasks?.map((task) => (
        <TaskCard key={task.id} {...task} />
      ))}
    </>
  );
};
