import { Nav, Navbar, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const menus = [
  {
    title: 'HOME',
    link: '/',
  },
  {
    title: 'ADVERTISING',
    link: '/#advertising',
  },
  {
    title: 'SERVICES',
    link: '/#services',
  },
  {
    title: 'RULES',
    link: '/#rules',
  },
  {
    title: 'HELP',
    link: '/#help',
  },
  {
    title: 'CONTACTS',
    link: '/#contacts',
  },
];

export const Header = () => {
  return (
    <header>
      <Navbar className='py-3' expand='lg' bg='light' data-bs-theme='light'>
        <Container>
          <Navbar.Brand href='/'>
            <svg width='183' height='42'>
              <use xlinkHref='#main-logo'></use>
            </svg>
          </Navbar.Brand>
          <Navbar.Text className='ms-auto me-3 d-lg-none'>
            <Link to={'/signin'}>Login</Link>
          </Navbar.Text>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto py-lg-0 pt-5 pb-3'>
              {menus?.map(({ title, link }) => (
                <Nav.Link key={link} href={link}>
                  {title}
                </Nav.Link>
              ))}
            </Nav>
            <Navbar.Text className='me-3 d-none d-lg-block'>
              <Link to={'/signin'}>Login</Link>
            </Navbar.Text>

            <Button className='mb-lg-0 mb-4'>Get Started Free</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
