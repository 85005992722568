import cn from 'classnames';
import { DefaultBadge } from '../DefaultBadge';
import styles from './BorderedBadge.module.scss';

export const BorderedBadge = (props) => {
  const { className, children } = props;

  return (
    <DefaultBadge className={cn(styles.badge, className)}>
      {children}
    </DefaultBadge>
  );
};
