import cn from 'classnames';
import styles from './Feedback.module.scss';

export const Feedback = (props) => {
  const { type, children } = props;

  return (
    <span
      className={cn(styles.feedback, { [styles.invalid]: type === 'invalid' })}
    >
      {children}
    </span>
  );
};
