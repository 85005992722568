import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useYoutubeLoginMutation } from '../../redux/api';

export const Youtube = () => {
  const [searchParams] = useSearchParams();

  const [youtubeLogin, { isSuccess, isError }] = useYoutubeLoginMutation();

  useEffect(() => {
    const url = window?.location?.href;
    const state = searchParams.get('state');

    if (url && state) {
      youtubeLogin({ url, state });
    }
  }, [searchParams]);

  return (
    <>
      {isSuccess && <Navigate to='/' />}
      {isError && (
        <>
          <div>Login is failure</div>
          <Link to={'/'}>Return to Home Page</Link>
        </>
      )}
    </>
  );
};
