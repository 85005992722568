import { Button, Col, Row } from 'react-bootstrap';
import { RoundedAvatar } from '@/components/ui/avatars';
import { Youtube } from '@/components/ui/icons';
import Card from '@/components/Card';
import { Stats } from '@/components/Stats';
import cn from 'classnames';
import Item from '../Search/List/Item';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { CreateOfferForm } from '@/components/forms';
import styles from './Channel.module.scss';
import { Dashboard } from '../../components/Layouts';

export const Channel = (props) => {
  const {} = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <section className={cn(styles.header, 'mb-5')}>
        <RoundedAvatar className={styles.avatar} size='large' />
        <h1 className={cn(styles.name, 'fs-2 m-0 fw-bold')}>Viki Melano</h1>
        <Row>
          <Col xs={'auto'}>
            <div className={styles.network}>
              <Youtube /> Youtube
            </div>
          </Col>
          <Col>
            <div className={styles.location}>USA, Los-Angeles</div>
          </Col>
        </Row>
      </section>
      <div className={styles.body}>
        <section>
          <h2 className='fw-bold fs-5'>Short description</h2>
          <p className={styles.descr}>
            The audience is growing rapidly, the vids keep flying in, an endless
            number of ideas. But I do it all for free .. Without earning a
            single ruble on the content. Therefore, I am waiting for advertisers
            on my channel! We will advertise your product to the maximum! On
            your terms!
          </p>
        </section>
        <aside className={styles.aside}>
          <Card className='d-grid'>
            <div className='fw-bold fs-5'>$1,700 — $1,800</div>
            <div className={cn(styles.cost, 'mb-4')}>Cost by post</div>
            <div className={cn(styles.stats, 'mb-4')}>
              <Stats
                className='mb-3'
                size='small'
                prop={'Contact Email'}
                value={'ho******@yahoo.com'}
              />
              <Stats
                className='mb-3'
                size='small'
                prop={'Subscribers'}
                value={'1.745.038 '}
              />
              <Stats
                className='mb-3'
                size='small'
                prop={'Engagement rating'}
                value={'6.75 % '}
              />
              <Stats
                size='small'
                prop={'Rating / Deals'}
                value={'9.75 / 9.87 '}
              />
            </div>
            <Button onClick={handleShow}>Send offer</Button>
          </Card>
        </aside>
        <section>
          <h2 className='fw-bold fs-5 mb-4'>Other channels</h2>
          <Item
            className='mb-4'
            title='Elena Matveeva'
            description='I am a popular blogger and musician, I have almost 2M subscribers on my YouTube channel, I specialize in short videos (shorts) advertising integrations usually gain a large number of views and get a good return for the customer'
          />
          <Item
            title='Elena Matveeva'
            description='I am a popular blogger and musician, I have almost 2M subscribers on my YouTube channel, I specialize in short videos (shorts) advertising integrations usually gain a large number of views and get a good return for the customer'
          />
        </section>
      </div>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <div className='fs-4 fw-bold'>Create an offer</div>
        </Modal.Header>
        <Modal.Body>
          <p className={styles.modalDescr}>
            Tell us a little more about what the blogger should do. The text you
            specify will become the basis of your deal and chat with the blogger
          </p>
          <CreateOfferForm />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const LayoutedChannel = () => {
  return (
    <Dashboard title='Search bloggers'>
      <Channel />
    </Dashboard>
  );
};
