import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Dashboard, Main } from './components/Layouts';
import { useGetUserQuery } from './redux/api';
import { Sprite } from './components/ui/Sprite';
import OAuth from './components/OAuth/';
import { PrivateRoute } from './components/routs/PrivateRoute';
import { SignIn, SignUp, Confirm, Google, Youtube } from './screens';
import { LayoutedHome } from './screens/Home';
import { Privacy } from './screens/Privacy';
import { LayoutedSearch } from './screens/Search';
import { LayoutedChannel } from './screens/Channel';
import { LayoutedHelpCenter } from './screens/HelpCenter';
import { LayoutedQuestion } from './screens/Question';
import { LayoutedSettings } from './screens/Settings';
import { LayoutedOffers } from './screens/Offers';
import { LayoutedOffer } from './screens/Offer';
import { LayoutedTasks } from './screens/Tasks';
import { LayoutedTask } from './screens/Task';
import { LayoutedPayouts } from './screens/Payouts';
import { LayoutedStatements } from './screens/Statements';

import './styles/app.scss';

const routes = [
  {
    path: '/',
    component: <LayoutedHome />,
  },
  {
    path: '/privacy',
    component: <Privacy />,
  },
  {
    path: '/search',
    component: <LayoutedSearch />,
    isPrivate: true,
  },
  {
    path: '/search/:slug',
    component: <LayoutedChannel />,
    isPrivate: true,
  },
  {
    path: '/help-center',
    component: <LayoutedHelpCenter />,
    isPrivate: true,
  },
  {
    path: '/help-center/:slug',
    component: <LayoutedQuestion />,
    isPrivate: true,
  },
  {
    path: '/settings',
    component: <LayoutedSettings />,
    isPrivate: true,
  },
  {
    path: '/offers',
    component: <LayoutedOffers />,
    isPrivate: true,
  },
  {
    path: '/offers/:slug',
    component: <LayoutedOffer />,
    isPrivate: true,
  },
  {
    path: '/tasks',
    component: <LayoutedTasks />,
    isPrivate: true,
  },
  {
    path: '/tasks/:slug',
    component: <LayoutedTask />,
    isPrivate: true,
  },
  {
    path: '/income/payouts',
    component: <LayoutedPayouts />,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/income/statements',
    component: <LayoutedStatements />,
    isPrivate: true,
  },
  {
    path: '/oauth/youtube/',
    component: <OAuth />,
  },
  {
    path: '/signup',
    component: <SignUp />,
  },
  {
    path: '/signup/confirm',
    component: <Confirm />,
  },
  {
    path: '/signin',
    component: <SignIn />,
  },
  {
    path: '/login/google',
    component: <Google />,
  },
  {
    path: '/account/youtube',
    component: <Youtube />,
  },
];

function App() {
  const { data, isLoading } = useGetUserQuery();

  return (
    <>
      <Sprite />
      <BrowserRouter>
        <Routes>
          {routes?.map(({ path, component, isPrivate }) => (
            <Route
              key={path}
              path={path}
              element={
                isPrivate ? <PrivateRoute>{component}</PrivateRoute> : component
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
