import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getApiUrl } from './getApiUrl';
import { addUser, removeUser } from './slices/userSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: getApiUrl('API_URL'),
  credentials: 'include',
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const isLogin = result.meta.request.url.includes('/api/login/user/');

  if (result.error && result.error.status === 401 && !isLogin) {
    // try to get a new token
    const refreshResult = await baseQuery(
      {
        url: '/api/token/refresh/',
        method: 'POST',
        body: { refresh: '' },
      },

      api,
      extraOptions
    );
    if (refreshResult.data) {
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(removeUser());
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    // auth api
    getUser: build.query({
      query: () => '/api/user/',
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(addUser(data));
        } catch (error) {
          dispatch(removeUser());
        }
      },
    }),

    getGoogleLogin: build.query({
      query: () => '/api/login/google/',
    }),

    googleLogin: build.mutation({
      query: (body) => ({
        url: '/api/login/google/',
        method: 'POST',
        body,
      }),

      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(addUser(data.user));
        } catch (error) {}
      },
    }),

    login: build.mutation({
      query: (body) => ({
        url: '/api/login/user/',
        method: 'POST',
        body,
      }),

      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(addUser(data.user));
        } catch (error) {}
      },
      invalidatesTags: ['User'],
    }),

    logout: build.mutation({
      query: (body) => ({
        url: '/api/token/logout/',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(removeUser());
        } catch (error) {}
      },
      // invalidatesTags: ['User'],
    }),

    signup: build.mutation({
      query: (body) => ({
        url: '/api/login/registration/',
        method: 'POST',
        body,
      }),
      // onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      //   try {
      //     const { data } = await queryFulfilled;
      //     dispatch(addUser(data.user));
      //   } catch (error) {}
      // },
    }),
    verify: build.mutation({
      query: (body) => ({
        url: '/api/login/registration/verify-email/',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(addUser(data.user));
        } catch (error) {}
      },
    }),

    //

    // youtube api
    getYoutubeAccount: build.query({
      query: () => '/api/account/youtube/',
      providesTags: ['User'],
    }),

    getYoutubeList: build.query({
      query: () => '/api/youtube/list/',
      transformResponse: (youtubeList) => {
        console.log(youtubeList);
        return youtubeList?.map((item) => ({
          title: item.snippet.title,
          login: item.snippet.customUrl,
          description: item.snippet.description,
          time: item.snippet.publishedAt,
          avatar: item.snippet.thumbnails.default.url,
          stats: {
            subscribers: item.stats.subscriberCount,
          },
          from: 'Youtube',
        }));
      },
    }),

    youtubeLogin: build.mutation({
      query: (body) => ({
        url: '/api/account/youtube/',
        method: 'POST',
        body,
      }),
    }),
    //
  }),
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useSignupMutation,
  useGetGoogleLoginQuery,
  useGoogleLoginMutation,
  useRefreshMutation,
  useGetYoutubeListQuery,
  useGetYoutubeAccountQuery,
  useYoutubeLoginMutation,
  useVerifyMutation,
} = api;
