import { Dashboard } from '@/components/Layouts';
import { ButtonsGroupFilter } from '@/components/Filters/ButtonsGroupFilter';
import { useSearchParams } from 'react-router-dom';
import styles from './Tasks.module.scss';
import { Kanban } from './Kanban';
import { List } from './List';

const buttons = [
  {
    value: '0',
    name: 'Kanban Desk',
  },
  {
    value: '1',
    name: 'Tasks list',
  },
  // {
  //   value: '2',
  //   name: 'Gantt Chart',
  // },
];

const tasks = [
  //
  {
    id: 48100,
    all: 48,
    completed: 40,
    date: '17 Dec 2022',
    status: 'pending',
  },
  {
    id: 48101,
    all: 36,
    completed: 5,
    date: '17 Dec 2022',
    status: 'pending',
  },
  {
    id: 48102,
    all: 30,
    completed: 22,
    date: '17 Dec 2022',
    status: 'pending',
  },
  //
  {
    id: 48110,
    all: 30,
    completed: 28,
    date: '17 Dec 2022',
    status: 'progress',
  },
  {
    id: 48111,
    all: 32,
    completed: 10,
    date: '17 Dec 2022',
    status: 'progress',
  },
  {
    id: 48112,
    all: 15,
    completed: 7,
    date: '17 Dec 2022',
    status: 'progress',
  },
  {
    id: 48113,
    all: 22,
    completed: 22,
    date: '17 Dec 2022',
    status: 'progress',
  },
  {
    id: 48114,
    all: 41,
    completed: 17,
    date: '17 Dec 2022',
    status: 'progress',
  },
  {
    id: 48115,
    all: 13,
    completed: 2,
    date: '17 Dec 2022',
    status: 'progress',
  },
  //
  {
    id: 48121,
    all: 25,
    completed: 16,
    date: '17 Dec 2022',
    status: 'done',
  },
  {
    id: 48122,
    all: 19,
    completed: 3,
    date: '17 Dec 2022',
    status: 'done',
  },
  {
    id: 48123,
    all: 30,
    completed: 22,
    date: '17 Dec 2022',
    status: 'done',
  },
  {
    id: 48124,
    all: 30,
    completed: 22,
    date: '17 Dec 2022',
    status: 'done',
  },
];

export const Tasks = (props) => {
  const {} = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const filterChangeHandler = (value) => {
    setSearchParams({ view: value });
  };

  const isKanban =
    searchParams.get('view') === '0' || !searchParams.get('view');

  const isList = searchParams.get('view') === '1';

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <ButtonsGroupFilter
          buttons={buttons}
          initialValue={searchParams.get('view') || '0'}
          searchProp='view'
          onChange={filterChangeHandler}
        />
        <div className={styles.amount}>{tasks?.length} tasks</div>
      </div>
      {isKanban && <Kanban tasks={tasks} />}
      {isList && <List tasks={tasks} />}
    </div>
  );
};

export const LayoutedTasks = () => {
  return (
    <Dashboard title='Tasks'>
      <Tasks />
    </Dashboard>
  );
};
