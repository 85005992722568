import { configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import userSlice from './slices/userSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,

    user: userSlice,
  },
  middleware: (getDefaultMiddlware) =>
    getDefaultMiddlware().concat(api.middleware),
});
