import { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './PageNav.module.scss';

const list = [
  {
    title: 'title-1',
    href: '#title1',
  },
  {
    title: 'title-2',
    href: '#title2',
  },
  {
    title: 'title-3',
    href: '#title3',
  },
  {
    title: 'title-4',
    href: '#title4',
  },
  {
    title: 'title-5',
    href: '#title5',
  },
];

export const PageNav = (props) => {
  const {} = props;

  const [activeId, setActiveId] = useState('');

  const anchorClickHandler = (evt) => {
    evt.preventDefault();
    const { href } = evt.target;
    const id = href.split('#')[1];

    const scrollPathLength = document.getElementById(id).offsetTop;
    window.scrollTo({ top: scrollPathLength - 100, behavior: 'smooth' });
    setActiveId(id);
  };

  useEffect(() => {
    const entries = [];

    list.forEach(({ href }) => {
      const entry = document.getElementById(href.replace('#', ''));
      entries.push(entry);
    });

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    const windowHeight = window.innerHeight;

    const options = {
      rootMargin: `${(-windowHeight * 1) / 10}px 0px ${
        (-windowHeight * 2) / 3
      }px 0px`,
    };

    const observer = new IntersectionObserver(callback, options);

    entries.forEach((entry) => observer.observe(entry));
  }, []);

  return (
    <nav>
      <ul className={styles.list}>
        {list?.map(({ title, href }) => (
          <li key={href}>
            <a
              className={cn(styles.link, {
                [styles.activeLink]: href.replace('#', '') === activeId,
              })}
              onClick={anchorClickHandler}
              href={href}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
