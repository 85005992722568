import { useEffect, useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Breadcrumb } from '@/components/Breadcrumb';
import { Col, Row } from 'react-bootstrap';
import { ListGroup } from '@/components/ListGroup';
import Card from '@/components/Card';
import { PageNav } from '@/components/PageNav';
import cn from 'classnames';
import styles from './Question.module.scss';
import { Dashboard } from '../../components/Layouts';

const questions = [
  {
    title: 'How quickly will I receive the money after confirmation?',
    slug: 'how-quickly-will-i-receive-the-money-after-confirmation',
  },
  {
    title: 'Can I pay to bloggers for goods?',
    slug: 'can-i-pay-to-bloggers-for-goods',
  },
  {
    title: 'How quickly will I receive the money after confirmation?',
    slug: 'how-quickly-will-i-receive-the-money-after-confirmation',
  },
  {
    title: 'Can I pay to bloggers for goods?',
    slug: 'can-i-pay-to-bloggers-for-goods',
  },
];

export const Question = () => {
  const { slug } = useParams();
  const { state, pathname } = useLocation();

  const basePath = useMemo(() => pathname.replace(slug, ''), [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Breadcrumb className='mb-5'>
        <Breadcrumb.Item linkAs='span'>
          <Link to={'/help-center/'}>Help Center</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{slug}</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className='fs-1 fw-bold mb-5'>{state?.title}</h1>
      <Row className='align-items-start'>
        <Col xs={12} sm={8}>
          <div className='mb-4'>
            <h4 id='title1'>Title 1</h4>
            <p>
              Please get acquainted with terms of services of Epicstars service.
              By using the service and derivations you are agreeing to this
              agreement. There is June 20 2020 version. The Administration of
              the website reserves the right to change the Agreement without
              previous notice, but not without nullifying the other ways of
              notice (through e-mail or website).
            </p>
            <p>
              Welcome to https://epicstars.com. The Website Administration
              offers you the services in accordance with conditions, which are
              subject of this Customer Agreement (hereinafter referred to as
              «Agreement», «CA»). In this regard you shall consult and carefully
              read the applicable terms of the Customer Agreement.
            </p>
            <h4 id='title2'>Title 2</h4>
            <p>
              Please get acquainted with terms of services of Epicstars service.
              By using the service and derivations you are agreeing to this
              agreement. There is June 20 2020 version. The Administration of
              the website reserves the right to change the Agreement without
              previous notice, but not without nullifying the other ways of
              notice (through e-mail or website).
            </p>
            <p>
              Welcome to https://epicstars.com. The Website Administration
              offers you the services in accordance with conditions, which are
              subject of this Customer Agreement (hereinafter referred to as
              «Agreement», «CA»). In this regard you shall consult and carefully
              read the applicable terms of the Customer Agreement.
            </p>
            <h4 id='title3'>Title 3</h4>
            <p>
              Please get acquainted with terms of services of Epicstars service.
              By using the service and derivations you are agreeing to this
              agreement. There is June 20 2020 version. The Administration of
              the website reserves the right to change the Agreement without
              previous notice, but not without nullifying the other ways of
              notice (through e-mail or website).
            </p>
            <p>
              Welcome to https://epicstars.com. The Website Administration
              offers you the services in accordance with conditions, which are
              subject of this Customer Agreement (hereinafter referred to as
              «Agreement», «CA»). In this regard you shall consult and carefully
              read the applicable terms of the Customer Agreement.
            </p>
            <h4 id='title4'>Title 4</h4>
            <p>
              Please get acquainted with terms of services of Epicstars service.
              By using the service and derivations you are agreeing to this
              agreement. There is June 20 2020 version. The Administration of
              the website reserves the right to change the Agreement without
              previous notice, but not without nullifying the other ways of
              notice (through e-mail or website).
            </p>
            <p>
              Welcome to https://epicstars.com. The Website Administration
              offers you the services in accordance with conditions, which are
              subject of this Customer Agreement (hereinafter referred to as
              «Agreement», «CA»). In this regard you shall consult and carefully
              read the applicable terms of the Customer Agreement.
            </p>
            <h4 id='title5'>Title 5</h4>
            <p>
              Please get acquainted with terms of services of Epicstars service.
              By using the service and derivations you are agreeing to this
              agreement. There is June 20 2020 version. The Administration of
              the website reserves the right to change the Agreement without
              previous notice, but not without nullifying the other ways of
              notice (through e-mail or website).
            </p>
            <p>
              Welcome to https://epicstars.com. The Website Administration
              offers you the services in accordance with conditions, which are
              subject of this Customer Agreement (hereinafter referred to as
              «Agreement», «CA»). In this regard you shall consult and carefully
              read the applicable terms of the Customer Agreement.
            </p>
          </div>
          <div>
            <h3 className={styles.questionsTitle}>This also may help you</h3>
            <Card className='py-0'>
              <ListGroup>
                {questions?.map(({ title, slug }, idx) => (
                  <ListGroup.Item key={idx}>
                    <Link
                      className='text-reset'
                      to={`${basePath}${slug}`}
                      state={{ title }}
                    >
                      {title}
                    </Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </div>
        </Col>
        <Col
          xs={{ span: 3, offset: 1 }}
          as={'aside'}
          className={cn(styles.articleNav, 'd-none d-sm-block')}
        >
          <PageNav />
        </Col>
      </Row>
    </div>
  );
};

export const LayoutedQuestion = () => {
  return (
    <Dashboard title='Help Center'>
      <Question />
    </Dashboard>
  );
};
